import React, { useState, useEffect } from "react";
import classNames from "classnames";
// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import ArrowBack  from "@mui/icons-material/ArrowBack";
import Add  from "@mui/icons-material/Add";
import Remove  from "@mui/icons-material/Remove";
// core components
import AppBar from "@mui/material/AppBar";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Confirmacao from "components/Dialogs/Confirmacao.js";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import AddProd from "views/PrincipalPage/SelecionaProd_selecionaSabor.jsx";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import styles from "assets/jss/material-kit-react/views/carrinhoCompra.js";
import  "assets/css/CarrinhoCompra.css";

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";

import 'prevent-pull-refresh';

import axios from 'axios';
import { IconButton } from "@mui/material";

import Link from '@mui/material/Link';

import { BS_ALE, geraHorarios } from "funcs/MyFuncs";

import { useNavigate } from "react-router-dom";

const delayProcessando     = 0;
const tempoMinProcess      = 0;

class CarrinhoCompra extends React.Component {

    arrPosItems  = [];    
    arrMenuItems = [];
    cardBody     = null;
    myMenu       = null;
    cabecalho    = null;

    ultElementMenu = -1;

    constructor(props) {
        super(props);
        
        let localCart = global.ProductObj.getCarrinhoCompra();
        
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.state = { 
            carrinhoCompra: localCart,
            alertaNewSabor: false,
            tmpQtdProd: 0,
            tmpProdSelect : [],
            tmpProdIndex : -1,
            alertaConfExc: false,
            alertaConfLimpeza: false,
            alertaAddProd : false,
            confContSemLog: false,
        };

        this.updateDimensions = this.updateDimensions.bind(this);

    }

    componentDidMount(){

        var parametros = 'parresid='  + this.state.usr_id + "&parverapp=" + 23;
        this.setState({ 'erroDB' : false });

        localStorage.setItem(global.ProductObj.chaveLocalStorage + '_timestamp', new Date());

        this.updateDimensions();
        //window.addEventListener('scroll', this.updateDimensions);
        window.addEventListener('scroll', this.updateDimensions);

        try{

            this.handleLoad();

        }catch (err){
        //BS_ALE(this,  '', 'Não foi possível atualizar as informações, tente novamente mais tarde', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            this.setState({ 'erroDB' : true });
        } 
        //this.verificaAtt();
        
        window.scrollTo(0, 0);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateDimensions);
    }
    
    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = alturaItems / (alturaScroll);

        var tmpPos = 0;
        var itemAtual = 0;

        for (var i = 0; i < this.arrPosItems.length; i++){
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ( (scrolledY * proporcao) < ( ( (this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop) ){
                break;
            }else{
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }        

        if (this.state.posItemClicado == -1){
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }else if (scrolledY == this.state.posItemClicado){

            if (this.state.eventClicado == -1){
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual});
                }
    
                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
                this.setState({ 
                    posItemClicado: -1,
                });
            }else{
                this.myMenu.scrollTo({left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth'})
                this.setState({ 
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado : -1,
                });
            }
        }else{
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});
            }
        }


    }

    atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {
        
        try {
    
            var CancelToken = axios.CancelToken;
    
            let source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
            }, 20000);
    
            
            await axios.post(
              global.urlServer + metodo, 
              parametros,
              {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                }
              }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == '1') {
                  arrLista = JSON.parse(JSON.stringify(responseData.registros));
                  
                  var arrFinal = [];
    
                  if (arrLista){
                    arrLista.map((prop, key) => {
                      arrFinal.push({
                        key : key, 
                        codInterno : prop.idGrupo, 
                        nome : prop.nome, 
                        precoDelivery : prop.posicao, 
                        descricao : global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                        image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                      });
                    });
                  }
                  
                  this.setState({
                    listaGruPro: arrFinal,
                  });
                }else{
                  this.setState({
                    erroDB : true,
                  });
                }
            })
            .catch((error) =>{
              if (error == '785936'){
    
              }else if(axios.isCancel(error)){
    
              }else{
            
    
              }
            });
        } catch (err) {
          
        }    
      } 

    clickProd(item){
        alert('Hello: ' + item.nome);
    }

    scrollToItem = (index) => {
        //alert('Hello: ' + index);
        //this.arrPosItems[index].scrollIntoView({behavior: 'smooth'});

        var offsetNextScroll = 80;

        var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / alturaScroll;

        var posicaoItem  = 0;

        this.arrPosItems.map((item, itemIndex) => {
            if (itemIndex == index){
                posicaoItem = (item) ? ( (item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop ) : 0;
            }
        });

        /*for (var i = 0; i < (index); i++){
            var tmpItem = this.arrPosItems[i];
            posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
        }

        posicaoItem -= this.state.scrollMarginTop;
        posicaoItem += this.cabecalho.clientHeight;
        posicaoItem = posicaoItem / proporcao;
        posicaoItem += offsetNextScroll;
        posicaoItem = Math.round(posicaoItem);*/

        window.scrollTo({top: posicaoItem, behavior: 'smooth'});

        this.setState({
            posItemClicado : posicaoItem,
            eventClicado   : index,
        });
        
    }

    renderMenu = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                if (index == this.state.itemAtivo){
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>ZZZZ - {item.nome}</a>
                            <div style={{marginTop: '4px', backgroundColor: '#f00'}} className={classes.separatorMenu}></div>
                        </div>
                    );    
                }else{    
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{item.nome}</a>
                        </div>
                    );                      
                }
                
            }else{
                return (
                    <div style={{ display: 'inline-block', }} ref={(ref) => this.arrMenuItems[index] = ref}>

                    </div>
                );
            }
            
        });
    }

    renderProds = (prodArrFinal) => {
        const { classes } = this.props;

        return prodArrFinal.map((prod, index) => {
            return (
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>{prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' )}</text>
                            </div>
                            <div>
                                <text style={{display: 'inline-block', fontSize:'13px', lineHeight: '18px'}}>{prod.descricao}</text>
                            </div>  
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>R$ {prod.preco.replace('.', ',')}</text>
                            </div>                  
                        </div>
                        <div style={{flex: 1, minWidth: '100px', height: '80px', display: "flex", justifyContent: "flex-end"}}>
                            <img src={global.siteurl + global.cod_restaurante + "/imgs/produtos/" + prod.imagem + ".png"} style={{width: '80px', height: "80px"}}></img>
                        </div>
                    </div>    
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            );
        });
    }

    renderEmbutidos = (produto) => {
        return produto.grupoAdics?.map( (value, index) => {
            return value.adicionais?.map( (value, index) => {
                if (value.checked){
                    return(
                        <text style={{fontSize: '16px', color: '#999', fontWeight: '400'}} >{
                            ( (value.checked > 1) ? value.checked + 'x ' : '' )
                            + value.nome}</text>
                    );
                }
            });    
        });
    }

    renderAdicionais = (sabor, produto) => {
        return sabor.grupoAdics?.map( (value, index) => {
            return value.adicionais?.map( (value, index) => {
                if (value.checked){
                    return(
                        <text style={{fontSize: '16px', color: '#999',}} >{
                            ( (value.checked > 1) ? value.checked + 'x ' : '+ ' ) 
                            + value.nome
                        }</text>
                    );
                }
            });    
        });
    }

    renderObservacao = (sabor, observacao) => {

        var remocoes = [];
        sabor.ingredientes.map( (value, index) => {
            if (value.checked){
                remocoes.push('Sem ' + value.nome);
            }   
        });

        remocoes.map( (value, index) => {
            if (observacao){
                if (observacao.length > 0){
                    if (index == (remocoes.length - 1)){
                        observacao += ' e ';
                    }else{
                        observacao += ', ';
                    }
                }
            }else{
                observacao = "";
            }

            observacao += value;
        });

        if (observacao){
            if (observacao.length > 0){
                return(
                    <text style={{fontSize: '16px', color: '#999',}} >{'Observação: "' + observacao + '"'}</text>
                );
            }
        }
    }

    renderSabores = (qtde, sabor, produto) => {

        const { classes } = this.props;
        var qtdeSabores = sabor.length;

        return sabor.map( (value, index) => {
            var prod = value.produto;

            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                    <text style={{fontSize: '16px', color: '#000', fontWeight: '450'}} >{((qtdeSabores > 1) ? '1/' + qtdeSabores + ' ' : '') + prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' )}</text>
                    {this.renderAdicionais(prod, produto)}
                    {this.renderObservacao(prod, prod.observacao)}

                </div>

            );
        });
    }

    abreAlteraItem = (item, index) => {

        this.handleClick();

        this.setState({
            tmpQtdProd : item.qtde,
            tmpProdSelect : JSON.parse(JSON.stringify(item)),
            tmpProdIndex  : index,
        });
    }
    
    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            var sabor = item.sabores;
            var qtde  = item.qtde;

            var valorTot = (qtde * item.valorUnit).toFixed(2).replace('.', ',');

            var saboresNome = "";

            item.sabores.map( (value, index) => {

                var prod = value.produto;

                saboresNome += (saboresNome.length > 0) ? ' ' : '';

                saboresNome += ((item.qtdeSabores > 1) ? '1/' + item.qtdeSabores + ' ' : '') + prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' );
            });

            var strQtde = ((qtde % 1 != 0) ? qtde.toFixed(3) : qtde.toString()).replace('.', ',');
            var strValorUnit = '0,00';

            try {
                strValorUnit = item.valorUnit.replace('.', ',');   
            } catch (error) {
                
            }

            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column'}} onClick={() => {
                    this.abreAlteraItem(item, index);
                }}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '70%', display: 'flex', flexDirection: 'column'}}>
                            <text style={{fontSize: '16px', color: '#000', fontWeight: '420'}} >{saboresNome}</text>
                            <text style={{fontSize: '16px', color: '#888', fontWeight: 'normal'}} >{strQtde + " x " + strValorUnit}</text>
                        </div>   
                        <div style={{width: '30%'}}>
                            <div style={{fontSize: '17px', fontWeight: '420', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right'}}>R$ {valorTot}</div>
                        </div>
                    </div>
                    <div style={{margin: '14px'}} className={classes.separator}></div>
                </div>
            );
            
            
        });
    }

    pesquisaLista = (value) => {
        window.scrollTo({top: 0, behavior: 'auto'});
        this.setState({pesquisaTxt: value}, () => {
            this.updateDimensions();
        });
    }

    abrePesquisa = (pesquisaAberta) => {
        if (pesquisaAberta){
            this.setState({
                scrollMarginTop: 125
            });
        }else{
            this.setState({
                scrollMarginTop: 75
            });
        }        
    }

    comparaTexto(val1, val2){

        var arrPalavras = val2.split(' ');
    
        var resultado = true;
        arrPalavras.map((palavra, index) => {
          if (!this.trataTexto(val1).includes(this.trataTexto(palavra))){
            resultado = false;
          }
        });
    
        return resultado;
    }

    trataTexto(val){
        return (
            val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        );
    }

    verificaMensageKG(){
        if (!global.mensagemUnidadeShowed){
            var cart = this.state.carrinhoCompra;
            var temProdutoKg = false;
    
            temProdutoKg = cart.some((produto, index) => {
                return produto.sabores.some( (sabor, index) => {
                    var saborAtual = sabor.produto;
                    return (saborAtual.unidade == "KG");
                });
            });
    
            if (temProdutoKg) {
                global.mensagemUnidadeShowed = true;
                BS_ALE(this, 'Atenção', global.txtMensagemUnidade);
            }
        }
    }

    handleLoad() {
        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.setState({ 
            carrinhoCompra: localCart,
            infoObrigOpen: true,
        }, () => {
            this.verificaMensageKG();
        });    
    }

    renderCarrinho = () => {

        const { classes } = this.props;
        
        return (

            <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px'}}>
                <div>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'column', }}>
                            {this.renderLista(this.state.carrinhoCompra)}
                        </div>        
                    </div> 
                </div>
            </div>  

        );
    }

    verificaLimpaCarrinho = () => {
        //!@#!@#   global.ProductObj.limpaCarrinho();
        this.setState({
            alertaConfLimpeza: true
        });
    }

    renderLimpaCarrinho = () => {
        
        if (this.state.carrinhoCompra.length > 0){
            return (
                <div onClick={() => {this.verificaLimpaCarrinho()}} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '300px', right: '0px'}}>
                    <div style={{display: 'flex', width: '100%', height: '100%'}}>
                        <text style={{fontSize: '16px', textDecoration: 'underline', margin: 'auto 15px auto auto'}}>Limpar Carrinho</text>
                    </div>
                </div>
            );
        }
        
    }

    renderHeader = () => {

        var valorTotal = 0;

        this.state.carrinhoCompra.map((item, index) => {
            valorTotal += (item.valorUnit * item.qtde);
        });

        var strValorTotal = valorTotal.toFixed(2).replace('.', ',');

        return (
            <div style={{flexGrow: 1, fontSize: '20px', color:'#fff'}}>
                Seus Itens
                {this.renderLimpaCarrinho()}
            </div>
        );
    }

    handleCloseDialog = () => {
        this.handleClick();
    }

    clickChangeQtdeProd = (somar, unidade, unidadeMin, unidadeMul) => {
        /*var tmpQtde = this.state.tmpQtdProd;

        if (somar){
            tmpQtde = tmpQtde + 1
        }else{
            tmpQtde = tmpQtde - 1
        }

        tmpQtde = (tmpQtde <  1) ?  1 : tmpQtde;
        tmpQtde = (tmpQtde > 99) ? 99 : tmpQtde;

        this.setState({
            tmpQtdProd : tmpQtde
        });*/

        var tmpQtde = this.state.tmpQtdProd;
        
        var qtdeMudanca = (unidade == "KG") ? parseFloat(unidadeMul) : 1;

        var qtdeMinima  = (unidade == "KG") ? parseFloat(unidadeMin) : 1;

        if (somar){
            tmpQtde = tmpQtde + qtdeMudanca;
        }else{
            tmpQtde = tmpQtde - qtdeMudanca;
        }

        tmpQtde = (tmpQtde <  qtdeMinima) ?  qtdeMinima : tmpQtde;
        tmpQtde = (tmpQtde > 99) ? 99 : tmpQtde;

        tmpQtde = Math.round((tmpQtde + Number.EPSILON) * 1000) / 1000;

        global.ProductObj.produto.qtde = tmpQtde;

        this.setState({
            tmpQtdProd : tmpQtde
        });
    }

    changeProduto = () => {

        var localCart = global.ProductObj.mudaQtdeItem(this.state.tmpQtdProd, this.state.tmpProdIndex);

        this.setState({ 
            carrinhoCompra: localCart,
        });

        this.handleCloseDialog();
    }

    revisarItem = () => {
        // !@#!@#
        this.handleCloseDialog();
        if (global.ProductObj.leProdutoCarrinho(this.state.tmpProdIndex)){
            this.setState({
                alertaAddProd : true,
            });
        }else{
            this.setState({
                alertaNewSabor: false,
            },() => {

            })
            this.handleLoad();
            BS_ALE(this,  'Atenção', 'Seu carrinho expirou, selecione seus produtos novamente e faça seu pedido.');
        }
    }

    removeItem = () => {
        var localCart = global.ProductObj.excluiItem(this.state.tmpProdIndex);

        this.setState({ 
            carrinhoCompra: localCart,
        });

        this.handleCloseDialog();
    }

    handleOutsideClick = (e) => {
        if (this.dialog){
            if (!this.dialog.contains(e.target)) this.handleClick();
        }
    };

    handleClick = () => {

        var estadoModal = this.state.alertaNewSabor;
        
        this.setState(prevState => ({
            alertaNewSabor: !prevState.alertaNewSabor
        }), () => {
            
        });
    };

    refDialog = (dialog) => {
        this.dialog = dialog;
    }

    renderDialog = () => {

        const { classes } = this.props;

        var mProduto = this.state.tmpProdSelect;
        var valorUnitProduto  = (mProduto.valorUnit) ? mProduto.valorUnit : 0;
        var valorTotalProduto = (this.state.tmpQtdProd * valorUnitProduto).toFixed(2).replace('.', ',');
        
        if ((this.state.alertaNewSabor) && (mProduto.valorUnit)){

            var saboresNome = '';
            var qtdeSabores = mProduto.sabores.length;

            var qtdeAtual  = '';
            var qtdeMult   = '';

            var prodUnidade    = '';
            var prodUnidadeMin = '';
            var prodUnidadeMul = '';

            var sabor = mProduto.sabores;

            mProduto.sabores.map( (value, index) => {

                var prod = value.produto;

                saboresNome += (saboresNome.length > 0) ? ' ' : '';

                saboresNome += ((qtdeSabores > 1) ? '1/' + qtdeSabores + ' ' : '') + prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' );

                qtdeMult    = prod.unidadeMul;
                qtdeMult    = (typeof qtdeMult == "number") ? qtdeMult : parseFloat(qtdeMult);
                qtdeMult    = qtdeMult.toFixed( (prod.unidade == "KG") ? 3 : 0 ).replace('.' , ',');
                qtdeMult    = (prod.unidade == "KG") ? qtdeMult : '';

                prodUnidade    = prod.unidade;
                prodUnidadeMin = prod.unidadeMin;
                prodUnidadeMul = prod.unidadeMul;
            });

            qtdeAtual   = this.state.tmpQtdProd;
            qtdeAtual   = (typeof qtdeAtual == "number") ? qtdeAtual : parseFloat(qtdeAtual);
            qtdeAtual   = qtdeAtual.toFixed( (prodUnidade == "KG") ? 3 : 0 ).replace('.' , ',');

            return(
                <Dialog
                    open={this.state.alertaNewSabor}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.rootFullSize, paper: classes.paperFullSize }}
                    style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
                >
                    <DialogContent>
                        <div ref={this.refDialog.bind(this)}>
                            <div style={{display: 'flex', flexDirection: 'row',}}>
                                <div style={{width: '70%', display: 'flex', flexDirection: 'column'}}>
                                    <text style={{fontSize: '16px', color: '#000', fontWeight: 'normal'}} >{mProduto.grupoProds}</text>
                                    {this.renderEmbutidos(mProduto)}
                                    {this.renderSabores(mProduto.qtde, sabor, mProduto)}
                                </div>
                                <div style={{width: '30%',}}>
                                    <div style={{width: 'fit-content', fontWeight: '500', fontSize: '16px', marginRight: '12px', marginLeft: 'auto'}}>R$ {(mProduto.valorUnit) ? mProduto.valorUnit.replace('.', ',') : '0,00'}</div>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0 0px 0'}}>
                                <div style={{marginRight: '40px'}}><a onClick={() => this.revisarItem()} style={{fontWeight: '450', textDecoration: 'underline', color: '#27e'}}>Revisar Item</a></div>
                                <div><a onClick={() => this.setState({alertaConfExc : true})} style={{fontWeight: '450', textDecoration: 'underline', color: '#980000'}}>Excluir Item</a></div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0 0px 0'}}>
                                <div width='40%' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                    <text style={{fontSize:'15px', fontWeight:'500'}}>Quantidade deste produto</text>
                                </div>
                                <div width='60%' style={{marginLeft: 'auto', marginRight: '0px', display: 'flex', flexDirection: 'row'}}>
                                    <IconButton
                                        style={{color: '#ba2d20', display: 'flex', flexDirection: 'column'}}
                                        aria-label="open drawer"
                                        onClick={() => {
                                            this.clickChangeQtdeProd(false, prodUnidade, prodUnidadeMin, prodUnidadeMul);
                                        }}
                                    >
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <text style={{fontSize: '12px'}}>{qtdeMult}</text>
                                            <Remove />
                                        </div>
                                    </IconButton>
                                    <text style={{fontSize:'15px', fontWeight:'bold', marginBottom: 'auto', marginTop: 'auto'}}>{qtdeAtual}</text>
                                    <IconButton
                                        aria-label="open drawer"
                                        style={{color: '#339119', display: 'flex', flexDirection: 'column'}}
                                        onClick={() => {
                                            this.clickChangeQtdeProd(true, prodUnidade, prodUnidadeMin, prodUnidadeMul);
                                        }}
                                    >
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <text style={{fontSize: '12px'}}>{qtdeMult}</text>
                                            <Add />
                                        </div>                                        
                                    </IconButton>
                                </div>
                            </div> 
                            <div style={{display: 'flex', flexDirection: 'row', margin: '0px 0 20px 0'}}>
                                <div width='60%' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                    <text style={{fontSize:'15px', fontWeight:'500'}}>Valor total do produto</text>
                                </div>
                                <div width='40%' style={{marginLeft: 'auto', marginRight: '0px'}}>
                                    <text style={{fontSize:'15px', fontWeight:'500', paddingRight: '16px'}}>R$ {valorTotalProduto}</text>
                                </div>
                            </div> 
                            <div style={{display: 'flex', flexDirection: 'row', margin: '0 0 12px 0', width: '100%'}}>
                                <Button onClick={() => this.handleCloseDialog()} color="primary" style={{backgroundColor: '#ddd', width: '50%', margin: '0 8px 0 0px', color: '#000'}}>
                                    Voltar
                                </Button>
                                <Button onClick={() => this.changeProduto(mProduto)} color="primary" style={{backgroundColor: '#03a1fc', width: '50%', margin: '0 0 0 8px', color: '#fff'}}>
                                    Alterar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    handleCloseConfLimpeza = () => {
        this.setState({
            alertaConfLimpeza: false
        });
    }

    resolveAlerta = () => {
        this.setState({
            alertaConfExc : false,
        }, () => {
            this.removeItem();
        });
    }

    handleCloseConfExc = () => {
        this.setState({
            alertaConfExc: false
        });
    }

    renderDialogInfoObrig = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        return(
          <Dialog
              open={this.state.infoObrigOpen}
              onClose={() => {
                this.setState({
                    infoObrigOpen: false
                });
              }}
              disableBackdropClick 
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ container: classes.root, paper: classes.paperFullSize }}
              style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
          >
              <div className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
                <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                  <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}>{'global.infoObrigTitulo'}</text>
                  <div onClick={() => { this.setState({infoObrigOpen: false}); }} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                    <div style={{display: 'flex', width: '100%', height: '100%'}}>
                      <ArrowBack style={{ margin: 'auto', }}/>
                    </div>
                  </div>
                </div>                    
              </div>
              <DialogContent style={{width: '100%', height: '100%',}}>
                  <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                      <div>
                        <img src="https://i.pinimg.com/originals/33/b8/69/33b869f90619e81763dbf1fccc896d8d.jpg" style={{width: '100%', height: "100%", margin: '10px 0 10px 0'}}></img>
                        <div dangerouslySetInnerHTML={{ __html: global.infoObrig }} />
                      </div>
                  </div>
              </DialogContent>
          </Dialog>
        );
    }

    verificaLojaAberta = () => {

        try {
    
            var CancelToken = axios.CancelToken;

            var parametros = 
                "parres=" + global.cod_restaurante +
                "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
                
            }, 20000);
    
            
            axios.post(
                global.siteurl + "verifica_loja_aberta.php", 
                parametros,
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true
                }).then((response) => {
                    var responseData = response.data;
                    if (responseData.resposta == '1') {
                        if (responseData.aberto == '1'){
                            global.restAberto = "1";
                            this.finalizarCompra();
                        }else{
                            BS_ALE(this, 'Atenção', 
                                <div>
                                    A nossa loja online não está aberta no momento.
                                    <br/>
                                    <br/>
                                    <Link style={{textDecoration: 'underline'}} onClick={() => {
                                        BS_ALE(this, 'Horários', 
                                            <div style={{width: '100%'}}>
                                                <div style={{width: '215px'}}></div>
                                                {geraHorarios(global.horariosFunc)}
                                            </div>
                                        )
                                    }}>Ver horários</Link>
                                </div>
                            );
                        }
                    }else {
                        BS_ALE(this, 'Atenção', 
                            <div>
                                A nossa loja online não está aberta no momento.
                                <br/>
                                <br/>
                                <Link style={{textDecoration: 'underline'}} onClick={() => {
                                    BS_ALE(this, 'Horários', 
                                        <div style={{width: '100%'}}>
                                            <div style={{width: '215px'}}></div>
                                            {geraHorarios(global.horariosFunc)}
                                        </div>
                                    )
                                }}>Ver horários</Link>
                            </div>
                        );
                    }
                })
                .catch((error) =>{
                    if (error == '785936'){

                    }else if(axios.isCancel(error)){

                    }else{
            

                    }
                }
            );
        } catch (err) {
            
        }    
    }

    verificaFinalizarCompra = () => {
        if (global.restAberto == "1"){
            if (this.state.carrinhoCompra.length < 1){
                BS_ALE(this, 'Atenção', 'Você precisa inserir ao menos um produto para fazer seu pedido.')
            }else{
                this.finalizarCompra();
            }
        }else{
            this.verificaLojaAberta();
        }
    }

    finalizarCompra = () => {

        try {
    
            var CancelToken = axios.CancelToken;

            var parametros = 
                "&partoken="    + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
              
            }, 20000);
    
            
            axios.post(
              global.siteurl + "cliente_logado.php", 
              parametros,
              {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true
              }).then((response) => {

                

                var responseData = response.data;
                if (responseData.resposta == '1') {
                    this.props.navigate('finaliza-compra');
                }else if (responseData.resposta == "ERRINFO"){
                    BS_ALE(this,  'Atenção', 'Ainda faltam algumas informações no seu cadastro, informe-as nos campos a seguir e clique em SALVAR INFORMAÇÕES.');
                    this.props.navigate('login-page');
                }else{
                    if (responseData.tknsts == "0"){

                        if (global.pedidoNoCad == '1'){
                            this.setState({
                                confContSemLog : true
                            });
                        }else{
                            BS_ALE(this,  'Atenção', 'Você precisa entrar na sua conta para finalizar seu pedido.');
                            this.props.navigate('login-page');
                        }
                    }else{
                        BS_ALE(this,  'Atenção', responseData.msg );
                    }                     
                }
            })
            .catch((error) =>{
              if (error == '785936'){
    
              }else if(axios.isCancel(error)){
    
              }else{
            
    
              }
            });
        } catch (err) {
          
        }    

        
    }

    handleCloseAddProd = () => {

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        var jsonResult = [];
        if (!localCart) {
            localCart = [];
        }else{
            jsonResult = JSON.parse(JSON.stringify(localCart[this.state.tmpProdIndex]));
        }

        this.setState({
            alertaAddProd : false,
            tmpQtdProd : localCart[0].qtde,
            carrinhoCompra: localCart,
            tmpProdSelect : jsonResult
        })
    }

    renderDialogAddProd = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        return (

            <Dialog
                open={this.state.alertaAddProd}
                onClose={this.handleCloseAddProd}
                disableBackdropClick 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ container: classes.rootNoPadding, paper: classes.paperFullSize }}
                style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
            >
                <DialogContent ref={(ref) => {this.dialogContentAddProd = ref}} style={{width: '100%', height: '100%', padding: '0px'}}>
                    <AddProd 
                        vmxale={this.props.vmxale}
                        scrollFunction={() => {
                            this.dialogContentAddProd.scrollTo({top: 0, behavior: 'auto'});
                        }} 
                        closeFunc={() => {
                            this.handleCloseAddProd()
                        }}
                    />
                </DialogContent>
            </Dialog>

        );
    }

    render() {
        const { classes } = this.props;

        var valorTotal = 0;

        this.state.carrinhoCompra.map((item, index) => {
            valorTotal += (item.valorUnit * item.qtde);
        });

        var strValorTotal = valorTotal.toFixed(2).replace('.', ',');

        return (
            <div className={classes.myBody} style={{marginTop: '0px'}}>
                <div className={classes.container}>
                    <Card style={{minHeight: '100vh'}}>
                        <form className={classes.form}>
                            <Header context={this} defaultIcon={false} myBackgroundColor={"#222"} leftLinks={
                                <div style={{ whiteSpace: 'nowrap'}}>
                                    <div style={{width: '100%', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderHeader()}
                                    </div>                                    
                                </div>
                            } />
                            <CardBody>
                                <List ref={(ref) => this.cardBody = ref} className={classes.root}>
                                    <List className={classes.root} style={{marginTop: '20px'}}>
                                        {this.renderCarrinho()}
                                    </List>
                                </List>
                            </CardBody>
                        </form>                
                    </Card>
                </div>
                {this.renderDialogAddProd()}
                <Footer qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont footerExtra={
                    <div className={classes.buttonFooter}>
                        <div onClick={() => {this.verificaFinalizarCompra()}}  style={{fontWeight: '400', width: '100%', position: 'relative', display: 'flex'}}>
                            <div style={{width: 'max-content', backgroundColor: 'transparent', margin: 'auto 18px auto auto', fontSize: '14px'}}>{'R$ ' + strValorTotal}</div>
                            <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>AVANÇAR</div>
                        </div>                        
                    </div>
                } />
                {this.renderDialog()}
                <Confirmacao 
                    showAlert={this.state.alertaConfExc}
                    onClose={this.handleCloseConfExc}
                    callbackYes={() => {this.resolveAlerta()}} 
                    title={'Atenção'}
                    msg={'Você deseja mesmo remover este produto do seu carrinho?'}
                    callbackNo={() => {
                        this.handleCloseConfExc();
                    }}
                />
                <Confirmacao 
                    showAlert={this.state.alertaConfLimpeza}
                    onClose={this.handleCloseConfLimpeza}
                    callbackYes={() => {
                        global.ProductObj.limpaCarrinho();
                        this.setState({
                            refresh: '1',
                            alertaConfLimpeza: false
                        }, () => {
                            this.handleLoad();
                        });
                    }} 
                    title={'Atenção'}
                    msg={'Você deseja mesmo remover todos os produtos do seu carrinho?'}
                    callbackNo={() => {
                        this.handleCloseConfLimpeza();
                    }}
                />
                <Confirmacao 
                    showAlert={this.state.confContSemLog}
                    onClose={() => {
                        this.setState({
                            confContSemLog: false,
                        });
                    }}
                    callbackYes={() => {

                        // Continuar sem Logar
                        this.setState({
                            confContSemLog : false
                        }, () => {
                            
                            this.props.navigate('finaliza-compra');
                        });

                    }} 
                    title={'Atenção'}
                    msg={'Criando uma conta você só precisa preencher suas informações uma vez e poderá utilizá-las para qualquer pedido.\n\nMas se preferir pode fazer seu pedido sem se cadastrar.'}
                    callbackNo={() => {
                        
                        // Cadastrar usuário
                        this.setState({
                            confContSemLog : false
                        }, () => {
                            
                            this.props.navigate('login-page');
                        });

                    }}
                    btnSimText={"Continuar sem Logar"}
                    btnNaoText={"Fazer Login"}
                />
            </div>
        );
    }
}

export default withStyles(CarrinhoCompra, styles); ;
