import React from "react";
import { BS_ALE } from "./MyFuncs";

let produto;

export default class ProductObj extends React.Component {
    
    constructor(props){
        super(props);

        this.pesquisaProd = "";

        this.chaveLocalStorage = "@vmx-001/" + global.cod_restaurante + "cart";

        this.produto = {

            chaveLocalStorage : this.chaveLocalStorage,
            listaGruProdAtual : [],
            alterandoIndex : -1,
            qtde: 1,

            grupoProds:  '',
            grupoProdsCod:  '',
            grupoProdsMisto:  '',
            grupoProdsFideGera: 0,
            grupoProdsFideResg: 0,

            checkedFidelidade: 0,
            valorDescFidelidade: 0,

            qtdeSabores:  0,
            saborAtual:   0,
            sabores:     [],
            valorUnit:    0,
            
        }
    }

    limpaCarrinho = () => {
        this.produto = {

            chaveLocalStorage : this.chaveLocalStorage,
            listaGruProdAtual : [],
            alterandoIndex : -1,
            qtde: 1,
            grupoProds:  '',
            grupoProdsCod:  '',
            grupoProdsMisto:  '',
            grupoProdsFideGera : 0,
            grupoProdsFideResg : 0,
            checkedFidelidade: 0,
            valorDescFidelidade: 0,
            qtdeSabores:  0,
            saborAtual:   0,
            sabores:     [],
            valorUnit:    0,
            
        }

        localStorage.removeItem(this.chaveLocalStorage);
    }

    limpaCarrinhoTiming = () => {
        localStorage.removeItem(this.chaveLocalStorage);
    }

    resolveRemoveAdicao = (produto, adicionais) => {
        var contAdicChecked = 0;

        var mudaCheck = ((produto.maximo == 1) && (produto.minimo == 1));     

        if (adicionais.checked == 0){
            return false;
        }
        
        if (!adicionais.checked){
            adicionais.checked = 0;
        }

        if (adicionais.checked > 0){
            adicionais.checked -= 1;
        }
        

        this.recalculaPreco();

        return true;
    }

    resolveAddAdicao = (produto, adicionais) => {
        var contAdicChecked = 0;

        var mudaCheck = ((produto.maximo == 1) && (produto.minimo == 1));

        produto.adicionais.map( (value, index) => {
            if (mudaCheck) {
                value.checked = 0;
            }else{
                if (value.checked >= 1){
                    contAdicChecked = contAdicChecked + value.checked;
                }
            }
        });          

        if ((produto.maximo != 0) && (contAdicChecked >= produto.maximo)){
            return false;
        }
        
        if (!adicionais.checked){
            adicionais.checked = 0;
        }
        adicionais.checked += 1;

        this.recalculaPreco();

        return true;
    }

    changeSaborAtual = () => {
        this.produto.sabores[this.produto.saborAtual].mudando = true;
    }

    resolveAdicao = (produto, adicionais) => {
        if ((adicionais.checked < 1) || (!adicionais.checked)){

            var contAdicChecked = 0;

            var mudaCheck = ((produto.maximo == 1) && (produto.minimo == 1));

            produto.adicionais.map( (value, index) => {
                if (mudaCheck) {
                    value.checked = 0;
                }else{
                    if (value.checked >= 1){
                        contAdicChecked = contAdicChecked + value.checked;
                    }
                }
            });          

            if ((contAdicChecked >= produto.maximo) && (produto.maximo != 0)){
                return false;
            }
        }
        
        adicionais.checked = (adicionais.checked >= 1) ? 0 : 1;

        this.recalculaPreco();

        return true;
    }

    addSaborAdicoes(posicao){
        var arrPos = posicao.split('.');
        var produto = this.produto.sabores[this.produto.saborAtual].produto.grupoAdics[arrPos[0]];
        var adicionais = produto.adicionais[arrPos[1]];

        return this.resolveAddAdicao(produto, adicionais);
    }

    removeSaborAdicoes(posicao){
        var arrPos = posicao.split('.');
        var produto = this.produto.sabores[this.produto.saborAtual].produto.grupoAdics[arrPos[0]];
        var adicionais = produto.adicionais[arrPos[1]];

        return this.resolveRemoveAdicao(produto, adicionais);
    }

    changeSaborAdicoes(posicao){
        var arrPos = posicao.split('.');
        var produto = this.produto.sabores[this.produto.saborAtual].produto.grupoAdics[arrPos[0]];
        var adicionais = produto.adicionais[arrPos[1]];

        return this.resolveAdicao(produto, adicionais);
    }

    verificaAdicionais = () => {
        
        var grupoEmbut = this.produto.grupoAdics;

        var resultado = true;

        if (this.produto.saborAtual >= (this.produto.qtdeSabores - 1)){
            grupoEmbut.map( (value, index) => {
                var adicionais = value.adicionais;
    
                var contAdicChecked = 0;
                adicionais.map( (value, index) => {
                    if (value.checked >= 1){
                        contAdicChecked = contAdicChecked + value.checked;
                    }
                });          
    
                if (contAdicChecked < value.minimo){
                    resultado = false;
                }
            });
        }

        var value = this.getSaborAtual();

        if (value.produto){
            var grupoAdics = value.produto.grupoAdics;
            grupoAdics.map( (value, index) => {
                var adicionais = value.adicionais;
    
                var contAdicChecked = 0;
                adicionais.map( (value, index) => {
                    if (value.checked >= 1){
                        contAdicChecked = contAdicChecked + value.checked;
                    }
                });          
    
                if (contAdicChecked < value.minimo){
                    resultado = false;
                }
            });
        }        

        return resultado;
    }

    addSaborEmbutidos(posicao){
        var arrPos = posicao.split('.');
        var produto = this.produto.grupoAdics[arrPos[0]];
        var adicionais = produto.adicionais[arrPos[1]];

        return this.resolveAddAdicao(produto, adicionais);
    }

    removeSaborEmbutidos(posicao){
        var arrPos = posicao.split('.');
        var produto = this.produto.grupoAdics[arrPos[0]];
        var adicionais = produto.adicionais[arrPos[1]];

        return this.resolveRemoveAdicao(produto, adicionais);
    }

    changeEmbutidos = (posicao) => {
        var arrPos = posicao.split('.');
        var produto = this.produto.grupoAdics[arrPos[0]];
        var adicionais = produto.adicionais[arrPos[1]];

        return this.resolveAdicao(produto, adicionais);
    }

    changeSaborRemocoes(posicao){
        this.produto.sabores[this.produto.saborAtual].produto.ingredientes[posicao].checked = !(this.produto.sabores[this.produto.saborAtual].produto.ingredientes[posicao].checked);
    }

    setQtdeSabores(qtdeSabores, gruProd, grupoCod, grupoNome) {
        this.produto.grupoProdsCod = grupoCod;
        this.produto.qtde = 1;
        this.produto.grupoProdsMisto = gruProd.misto;
        this.produto.grupoProdsFideGera = (isNaN(gruProd.fide_beneficios)) ? 0 : gruProd.fide_beneficios;
        this.produto.grupoProdsFideResg = (isNaN(gruProd.fide_resgate)) ? 0 : gruProd.fide_resgate;
        this.produto.grupoProds = grupoNome;
        this.produto.qtdeSabores = qtdeSabores;

        this.produto.saborAtual = 0;
        this.produto.sabores = Array.from({length: qtdeSabores}, (v, i) => []);

        this.produto.listaGruProdAtual[0] = gruProd;

        this.produto.alterandoIndex = -1;
    }

    setSelectQtdeSabores(qtdeSabores, gruProd, grupoCod, grupoNome) {
        this.produto.grupoProdsCod = grupoCod;
        this.produto.qtde = 1;
        this.produto.grupoProdsMisto = gruProd.misto;
        this.produto.grupoProds = grupoNome;
        this.produto.grupoProdsFideGera = gruProd.fide_beneficios;
        this.produto.grupoProdsFideResg = gruProd.fide_resgate;
        this.produto.qtdeSabores = 0;

        this.produto.saborAtual = 0;
        this.produto.sabores = Array.from({length: qtdeSabores}, (v, i) => []);

        this.produto.listaGruProdAtual[0] = gruProd;

        this.produto.alterandoIndex = -1;
    }

    setOnlyQtdeSabores(qtde) {
        this.produto.qtdeSabores = qtde;

        this.produto.sabores = this.produto.sabores.slice(0, qtde);

        this.produto.saborAtual = 0;
    }

    setSaborAtual = (saborIndex) => {
        this.produto.saborAtual = saborIndex;
    }

    setSaborObs = (obsTXT) => {
        this.produto.sabores[this.produto.saborAtual].produto.observacao = obsTXT;
    }

    setSabor = (newSabor) => {
        
        if (this.produto.grupoProdsFideGera == 0){
            try {
                this.produto.grupoProdsFideGera = (isNaN(newSabor.grupoPontosBonusGer)) ? 0 : parseInt(newSabor.grupoPontosBonusGer);
            } catch (error) {
                this.produto.grupoProdsFideGera = 0
            }
        }

        if (this.produto.grupoProdsFideResg == 0){
            try {
                this.produto.grupoProdsFideResg = (isNaN(newSabor.grupoPontosBonusRes)) ? 0 : parseInt(newSabor.grupoPontosBonusRes);
            } catch (error) {
                this.produto.grupoProdsFideResg = 0
            }
        }
        
        

        if (this.produto.sabores[this.produto.saborAtual].mudando){
            if (this.produto.sabores[this.produto.saborAtual].produto.idProduto == newSabor.idProduto){
                this.produto.sabores[this.produto.saborAtual].mudando = false;
                return true;
            }
        }

        this.produto.sabores[this.produto.saborAtual].mudando = false;

        this.produto.sabores[this.produto.saborAtual].produto = JSON.parse(JSON.stringify(newSabor));

        this.produto.sabores[this.produto.saborAtual].produto.grupoAdics = [];
        this.produto.grupoAdics = [];

        this.produto.qtde = (newSabor.unidade == "KG") ? parseFloat(newSabor.unidadeMin) : this.produto.qtde;

        if (this.produto.listaGruProdAtual[0].grupoAdics) {
            this.produto.listaGruProdAtual[0].grupoAdics.map( (value, index) => {
                if (value.embutido == "1"){
                    this.produto.grupoAdics.push(JSON.parse(JSON.stringify(value)));
                }else{
                    this.produto.sabores[this.produto.saborAtual].produto.grupoAdics.push(JSON.parse(JSON.stringify(value)));
                }
            });
        }else if (this.produto.sabores[this.produto.saborAtual].produto.gruProdsAdics){
            this.produto.sabores[this.produto.saborAtual].produto.gruProdsAdics.map( (value, index) => {
                if (value.embutido == "1"){
                    this.produto.grupoAdics.push(JSON.parse(JSON.stringify(value)));
                }else{
                    this.produto.sabores[this.produto.saborAtual].produto.grupoAdics.push(JSON.parse(JSON.stringify(value)));
                }
            });
        }

        this.recalculaPreco();
    }

    getCarrinhoCompra = () => {

        if (localStorage.getItem(this.chaveLocalStorage + "_timestamp")){
            var datetimeSalvo = new Date(localStorage.getItem(this.chaveLocalStorage + "_timestamp"));

            var datetimeAtual = new Date();
            var diferencaEmMilissegundos = datetimeAtual - datetimeSalvo;
            var diferencaEmMinutos = diferencaEmMilissegundos / (1000 * 60);
    
            if (diferencaEmMinutos >= 180){
                this.limpaCarrinhoTiming();
                localStorage.removeItem(this.chaveLocalStorage + '_timestamp');
            }
        }
        
        return localStorage.getItem(this.chaveLocalStorage);
    }

    getSaborAtual = () => {
        return this.produto.sabores[this.produto.saborAtual];
    }

    getPrecoProdSemAdic = (arrResult) => {

        var valorProduto = 0;

        var tipoCalcMisto     = global.tipoCalcPrecoMisto;
        var tipoCalcAdicional = global.tipoCalcPrecoAdicional;

        var pontosBonusAdic = 0;

        this.produto.sabores.map( (value, index) => {
            if (value.produto){
                if (tipoCalcMisto == 1){
                    valorProduto = (valorProduto < value.produto.preco) ? parseFloat(value.produto.preco) : parseFloat(valorProduto)
                }else if (tipoCalcMisto == 2){
                    valorProduto = valorProduto + (parseFloat(value.produto.preco) / parseFloat(this.produto.sabores.length))
                }

                var grupoAdics = value.produto.grupoAdics;

                grupoAdics.map( (valueGrup, index) => {
                    valueGrup.adicionais.map( (value, index) => {
                        if (value.checked >= 1){

                            var pontosAnt = pontosBonusAdic;
                            try {

                                if (tipoCalcAdicional === '0'){
                                    //pontosBonusAdic += ((parseFloat(value.precod) * value.checked) * (2 / this.produto.sabores.length));

                                    var valorAdic = ((parseFloat(value.precod)) * (2 / this.produto.qtdeSabores));
                                    var pontosAdic = parseInt(Math.floor(valorAdic / global.programaFidelidadeFatorValMin));
                                    pontosAdic       = pontosAdic + parseInt(valueGrup.fide_beneficios);
                                    pontosBonusAdic += (pontosAdic < 0) ? 0 : ( pontosAdic * value.checked );

                                }else{
                                    //pontosBonusAdic += (parseFloat(value.precod) * value.checked)
                                    var valorAdic = (parseFloat(value.precod));
                                    var pontosAdic = parseInt(Math.floor(valorAdic / global.programaFidelidadeFatorValMin));
                                    pontosAdic       = pontosAdic + parseInt(valueGrup.fide_beneficios);
                                    pontosBonusAdic += (pontosAdic < 0) ? 0 : ( pontosAdic * value.checked );

                                }

                            } catch (error) {

                                pontosBonusAdic = pontosAnt;

                            }
                        }
                    });
                });
                
            }
        });

        if (this.produto.grupoAdics) {

            var grupoAdics = this.produto.grupoAdics;

            grupoAdics.map( (valueGrup, index) => {
                valueGrup.adicionais.map( (value, index) => {
                    if (value.checked >= 1){

                        var pontosAnt = pontosBonusAdic;
                        try {

                            var valorAdic = (parseFloat(value.precod));
                            var pontosAdic = parseInt(Math.floor(valorAdic / global.programaFidelidadeFatorValMin));
                            pontosAdic       = pontosAdic + parseInt(valueGrup.fide_beneficios);
                            pontosBonusAdic += (pontosAdic < 0) ? 0 : ( pontosAdic * value.checked );

                        } catch (error) {

                            pontosBonusAdic = pontosAnt;

                        }
                    }
                });
            });
        }

        if (global.programaFidelidadeAdicional == '1'){
            arrResult["pontosAdicBonus"] = pontosBonusAdic;
        }else{
            arrResult["pontosAdicBonus"] = 0;
        }

        return valorProduto;

    }

    recalculaPreco = () => {
        var tipoCalcMisto     = global.tipoCalcPrecoMisto;
        var tipoCalcAdicional = global.tipoCalcPrecoAdicional;

        var valorProduto      = 0;

        var saborTMP = this.produto.sabores[this.produto.saborAtual];

        this.produto.sabores.map( (value, index) => {
            if (value.produto){
                if (tipoCalcMisto == 1){
                    valorProduto = (valorProduto < value.produto.preco) ? parseFloat(value.produto.preco) : parseFloat(valorProduto)
                }else if (tipoCalcMisto == 2){
                    valorProduto = valorProduto + (parseFloat(value.produto.preco) / parseFloat(this.produto.sabores.length))
                }
            }
        });

        this.produto.sabores.map( (value, index) => {
            if (value.produto){
                var grupoAdics = value.produto.grupoAdics;

                grupoAdics.map( (value, index) => {
                    value.adicionais.map( (value, index) => {
                        if (value.checked >= 1){
                            if (tipoCalcAdicional === '0'){
                                valorProduto = valorProduto + ((parseFloat(value.precod) * value.checked) * (2 / this.produto.sabores.length));
                            }else{
                                valorProduto = valorProduto + (parseFloat(value.precod) * value.checked)
                            }
                        }
                    });
                });
            }
        });

        if (this.produto.grupoAdics) {
            this.produto.grupoAdics.map( (value, index) => {
                value.adicionais.map( (value, index) => {
                    if (value.checked >= 1){
                        valorProduto = valorProduto + (parseFloat(value.precod) * value.checked)
                    }
                });
            });
        }

        this.produto.valorUnit = valorProduto.toFixed(2);

        
    }

    leProdutoCarrinho = (index) => {

        let localCart = this.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
            return false;
        }else{
            this.produto = localCart[index];
            this.produto.alterandoIndex = index;
            this.produto.listaGruProdAtual = [];
            this.produto.saborAtual = 0;
            return true;
        }

    }

    excluiItem  = (index) => {

        let localCart = this.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        if (localCart.length > index){
            localCart.splice(index, 1);
        }

        let stringCart = JSON.stringify(localCart);
        localStorage.setItem(global.ProductObj.chaveLocalStorage, stringCart);

        return localCart;
    }

    mudaQtdeItem = (novaQtde, index) => {
        let localCart = this.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        if (localCart[index]){
            localCart[index].qtde = novaQtde; 
        }

        let stringCart = JSON.stringify(localCart);
        localStorage.setItem(global.ProductObj.chaveLocalStorage, stringCart);

        return localCart;
    }

    salvaProdutoCarrinho = () => {

        let localCart = this.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        let mySabores = [];
        this.produto.sabores.map( (value, index) => {
            if (value.produto.observacao){
                value.produto.observacao = value.produto.observacao.trim();
            }
            mySabores.push({
                produto : value.produto
            });
        });

        var produtoSalvar = {
            grupoProds      : this.produto.grupoProds,
            grupoProdsCod   : this.produto.grupoProdsCod,
            grupoProdsMisto : this.produto.grupoProdsMisto,
            grupoProdsFideGera : this.produto.grupoProdsFideGera,
            grupoProdsFideResg : this.produto.grupoProdsFideResg,
            checkedFidelidade: this.produto.checkedFidelidade,
            valorDescFidelidade: this.produto.valorDescFidelidade,
            qtde            : this.produto.qtde,
            qtdeSabores     : this.produto.qtdeSabores,
            grupoAdics      : this.produto.grupoAdics,
            valorUnit       : this.produto.valorUnit,
            sabores         : mySabores,
        };

        if (this.produto.alterandoIndex >= 0){
            localCart[this.produto.alterandoIndex] = produtoSalvar;
        }else{
            localCart.push(produtoSalvar);
        }

        let stringCart = JSON.stringify(localCart);
        localStorage.setItem(global.ProductObj.chaveLocalStorage + '_timestamp', new Date());
        localStorage.setItem(global.ProductObj.chaveLocalStorage, stringCart);
    }

    mergeProdutos = (pedProd, prodsDisponiveis) => {

        pedProd.map( (value, index) => {

            this.mergeProduto(value, prodsDisponiveis);
            if (value.MEIA.CODIGO_PRODUTO){
                this.mergeProduto(value.MEIA, prodsDisponiveis);
            }

            if (value.COPY_GRUPOPROD == ''){
                value.INDISPONIVEL = true;
            }

            if (value.MEIA.CODIGO_PRODUTO != ''){
                if (value.MEIA.COPY_GRUPOPROD == ''){
                    value.INDISPONIVEL = true;
                }
            }
            
        });

        return pedProd;
    }

    mergeAdicionais = (saborAtual) => {

        var value = saborAtual;

        saborAtual.grupoAdics.map( (grupoAdicAtual, grupoAdicIndex) => {
            grupoAdicAtual.adicionais.map( (adicionalAtual, adicionalIndex) => {
                var ingredientesSelecionados = value.ingredientes_tmp;
                return ingredientesSelecionados.map( (myIngValue, myIngIndex) => {
                    if (adicionalAtual.idAdicional == myIngValue.CODIGO_INGREDIENTE) {
                        adicionalAtual.checked = parseFloat(myIngValue.QUANTIDADE);

                        myIngValue.DISPONIVEL = true;
                    }
                });
            });
        });

        if (saborAtual.retira_ingr){
            saborAtual.retira_ingr.map( (valueIng, index) => {
                saborAtual.ingredientes.push({
                    "idIngred"  : "",
                    "idProduto" : "",
                    "nome"      : valueIng.nome,
                    "entraDesc" : "1",
                    "permRetir" : valueIng.permRetir,
                    "auxiliar"  : "",
                    "checked"   : valueIng.checked
                });
            });
        }
    }

    mergeProduto = (value, tmpProds) => {

        var prodsDisponiveis = JSON.parse(JSON.stringify(tmpProds));

        var grupoProdutoAtual = prodsDisponiveis.filter(function (grupoProd) { return grupoProd.codigo == value.COPY_GRUPOPRODCOD });
        var produtoDisponivelAtual = (grupoProdutoAtual.length > 0) ? grupoProdutoAtual[0].produtos.filter(function (produtoAtual) { 
            return produtoAtual.idProduto == value.CODIGO_PRODUTO 
        }) : [];

        if (grupoProdutoAtual.length > 0){
            if (grupoProdutoAtual[0].grupoAdics){
                grupoProdutoAtual[0].grupoAdics.map( (grupoAdicAtual, grupoAdicIndex) => {
                    grupoAdicAtual.adicionais.map( (adicionalAtual, adicionalIndex) => {
                        var ingredientesSelecionados = value.INGREDIENTES;
                        return ingredientesSelecionados.map( (myIngValue, myIngIndex) => {
                            if (adicionalAtual.idAdicional == myIngValue.CODIGO_INGREDIENTE) {
                                adicionalAtual.checked = parseFloat(myIngValue.QUANTIDADE);
    
                                myIngValue.DISPONIVEL = true;
                            }
                        });
                    });
                });
    
                var grupoIndisponiveis = grupoProdutoAtual[0].grupoAdics;
                value.INGREDIENTES.map( (myAdic, index) => {
                    if (myAdic.ADICIONA_RETIRA == 'A'){
                        if (!myAdic.DISPONIVEL){
                            if (!value.ADIC_INDISP){
                                value.ADIC_INDISP = [];
                            }
    
                            value.ADIC_INDISP.push(myAdic);
                        }
                    }
                });            
    
                value.GRUPO_ADICS = [];
                value.GRUPO_ADICS_EMB = [];
                grupoProdutoAtual[0].grupoAdics.map( (grupoAdicAtual, grupoAdicIndex) => {
                    if (grupoAdicAtual.embutido == '1'){
                        value.GRUPO_ADICS_EMB.push(grupoAdicAtual);
                    }else{
                        value.GRUPO_ADICS.push(grupoAdicAtual);
                    }
                });
            }

            value.fide_beneficios = grupoProdutoAtual[0].fide_beneficios;
            value.fide_resgate    = grupoProdutoAtual[0].fide_resgate;
        }

        if (produtoDisponivelAtual.length > 0){
            produtoDisponivelAtual[0].ingredientes.map( (ingredienteAtual, ingredienteIndex) => {
                var ingredientesSelecionados = value.INGREDIENTES;
                return ingredientesSelecionados.map( (myIngValue, myIngIndex) => {
                    if (ingredienteAtual.nome == myIngValue.DESCRICAO) {
                        ingredienteAtual.checked = true;
                    }
                });
            });

            value.INGREDIENTES = produtoDisponivelAtual[0].ingredientes;
        }

    }

    jsonProdPedToProductCarrinho = (pedProd, prodsDisponiveis) => {

        var tmpCarrinho = new Array();
        var tmpIndisponivel = new Array();
        var tmpProduto  = [];

        //!@#!@#

        pedProd = this.mergeProdutos(pedProd, prodsDisponiveis);

        for (var i = 0; i < pedProd.length; i++){

            if ((pedProd[i].COPY_GRUPOPROD != '') && (pedProd[i].COPY_GRUPOPROD != undefined)){
               
                tmpProduto = {

                    chaveLocalStorage : "@vmx-001/cart",
                    listaGruProdAtual : [],
                    alterandoIndex : -1,
                    qtde: 1,
                    grupoProds:  (pedProd[i].COPY_GRUPOPROD) ? pedProd[i].COPY_GRUPOPROD : '',
                    grupoProdsCod:  (pedProd[i].COPY_GRUPOPRODCOD) ? pedProd[i].COPY_GRUPOPRODCOD : '',
                    grupoProdsMisto:  (pedProd[i].COPY_GRUPOPRODMIS) ? pedProd[i].COPY_GRUPOPRODMIS : '',
                    qtdeSabores:  0,
                    saborAtual:   0,
                    sabores:     [],
                    valorUnit:    0,
                    grupoAdics:  [],
                    qtdeSabores: 1,
                    adicIndisp: pedProd[i].ADIC_INDISP
                };
                    
                tmpProduto.grupoProds       =  (pedProd[i].COPY_GRUPOPROD) ? pedProd[i].COPY_GRUPOPROD : '';
                tmpProduto.grupoProdsCod    =  (pedProd[i].COPY_GRUPOPRODCOD) ? pedProd[i].COPY_GRUPOPRODCOD : '';
                tmpProduto.grupoProdsMisto  =  (pedProd[i].COPY_GRUPOPRODMIS) ? pedProd[i].COPY_GRUPOPRODMIS : '';
    
                tmpProduto.qtde      = parseFloat(pedProd[i].QUANTIDADE);
                tmpProduto.valorUnit = this.convertValueToStr(pedProd[i].VALOR_UNITARIO);

                tmpProduto.grupoProdsFideGera = pedProd[i].fide_beneficios;
                tmpProduto.grupoProdsFideResg = pedProd[i].fide_resgate;
    
                tmpProduto.sabores.push({
                    "produto" : {
                        "idProduto": pedProd[i].CODIGO_PRODUTO,
                        "nomeProduto": pedProd[i].NOME,
                        "descricao": "",
                        "preco": pedProd[i].COPY_VALOR,
                        "promo": "0",
                        "permiteObs": pedProd[i].COPY_PEROBS,
                        "unidade"    : pedProd[i].UNIDADE,
                        "unidadeMin" : pedProd[i].COPY_UNIDADEMIN,
                        "unidadeMul" : pedProd[i].COPY_UNIDADEMUL,
                        "observacao" : (pedProd[i].COPY_PEROBS == '1') ? pedProd[i].OBSERVACOES : ''
                    }                
                });
    
                if (pedProd[i].INGREDIENTES){
    
                    let saborAtual = tmpProduto.sabores[tmpProduto.sabores.length - 1].produto;
    
                    saborAtual.grupoAdics = pedProd[i].GRUPO_ADICS;
    
                    tmpProduto.grupoAdics = pedProd[i].GRUPO_ADICS_EMB;
    
                    saborAtual.ingredientes = pedProd[i].INGREDIENTES;
                }
    
                if (pedProd[i].MEIA.NOME?.length > 0){
    
                    tmpProduto.qtdeSabores = 2;
    
                    tmpProduto.sabores.push({
                        "produto" : {
                            "idProduto": pedProd[i].MEIA.CODIGO_PRODUTO,
                            "nomeProduto": pedProd[i].MEIA.NOME,
                            "descricao": "",
                            "preco": pedProd[i].MEIA.COPY_VALOR,
                            "promo": "0",
                            "permiteObs":  pedProd[i].MEIA.COPY_PEROBS,
                            "unidade"    : pedProd[i].MEIA.UNIDADE,
                            "unidadeMin" : pedProd[i].MEIA.COPY_UNIDADEMIN,
                            "unidadeMul" : pedProd[i].MEIA.COPY_UNIDADEMUL,
                            "observacao" : (pedProd[i].MEIA.COPY_PEROBS == '1') ? pedProd[i].MEIA.OBSERVACOES : ''
                        }
                    });
        
                    if (pedProd[i].MEIA.INGREDIENTES){
        
                        let saborAtual = tmpProduto.sabores[tmpProduto.sabores.length - 1].produto;
        
                        saborAtual.grupoAdics = pedProd[i].MEIA.GRUPO_ADICS;
        
                        saborAtual.ingredientes = pedProd[i].MEIA.INGREDIENTES;
                    }
                  
                }
    
                if (pedProd[i].INDISPONIVEL){
    
                    var item = tmpProduto;
                    var saboresNome = "";
                    item.sabores.map( (value, index) => {
    
                        var prod = value.produto;
        
                        saboresNome += (saboresNome.length > 0) ? ' ' : '';
        
                        saboresNome += ((item.qtdeSabores > 1) ? '1/' + item.qtdeSabores + ' ' : '') + prod.nomeProduto;
                    });
                    tmpIndisponivel.push(saboresNome);
                }else{
                    tmpCarrinho.push(tmpProduto);
    
                    if (tmpProduto.adicIndisp){
                        tmpProduto.adicIndisp.map( (value, index) => {
                            tmpIndisponivel.push(value.DESCRICAO);
                        });
                    }
                }
                
            }

            
            
        }

        return {
            disponivel : tmpCarrinho,
            indisp     : tmpIndisponivel
        };
    }

    

    convertValueToStr = (value) => {

        var resultado = "";

        try{
            if(!isNaN(parseFloat(value))) {
                resultado = (Math.round(value * 100) / 100).toFixed(2);
            }
        }catch(e){
            resultado = '0.00';
        }
        

        return resultado;
    }

    jsonProdPedToProductObj = (pedProd) => {

        var tmpCarrinho = new Array();
        var tmpProduto  = [];

        for (var i = 0; i < pedProd.length; i++){

            tmpProduto = {

                chaveLocalStorage : "@vmx-001/cart",
                listaGruProdAtual : [],
                alterandoIndex : -1,
                qtde: 1,
                grupoProds:  (pedProd[i].COPY_GRUPOPROD) ? pedProd[i].COPY_GRUPOPROD : '',
                grupoProdsCod:  (pedProd[i].COPY_GRUPOPRODCOD) ? pedProd[i].COPY_GRUPOPRODCOD : '',
                grupoProdsMisto:  (pedProd[i].COPY_GRUPOPRODMIS) ? pedProd[i].COPY_GRUPOPRODMIS : '',
                checkedFidelidade: (pedProd[i].FIDELIDADE) ? pedProd[i].FIDELIDADE : '',
                valorDescFidelidade: (pedProd[i].FID_DESC) ? pedProd[i].FID_DESC : '',
                qtdeSabores:  0,
                saborAtual:   0,
                sabores:     [],
                valorUnit:    0,
                grupoAdics:  [],
                qtdeSabores: 1,
            };

            tmpProduto.grupoProds       =  (pedProd[i].COPY_GRUPOPROD) ? pedProd[i].COPY_GRUPOPROD : '';
            tmpProduto.grupoProdsCod    =  (pedProd[i].COPY_GRUPOPRODCOD) ? pedProd[i].COPY_GRUPOPRODCOD : '';
            tmpProduto.grupoProdsMisto  =  (pedProd[i].COPY_GRUPOPRODMIS) ? pedProd[i].COPY_GRUPOPRODMIS : '';

            tmpProduto.qtde      = this.convertValueToStr(pedProd[i].QUANTIDADE);
            tmpProduto.valorUnit = this.convertValueToStr(pedProd[i].VALOR_UNITARIO);

            tmpProduto.sabores.push({
                "produto" : {
                    "idProduto": "",
                    "nomeProduto": pedProd[i].NOME,
                    "descricao": "",
                    "preco": "",
                    "promo": "0",
                    "permiteObs":  "0",
                    "unidade"    : pedProd[i].UNIDADE,
                    "unidadeMin" : pedProd[i].COPY_UNIDADEMIN,
                    "unidadeMul" : pedProd[i].COPY_UNIDADEMUL,
                    "observacao" : pedProd[i].OBSERVACOES
                }                
            });

            if (pedProd[i].INGREDIENTES){

                let saborAtual = tmpProduto.sabores[tmpProduto.sabores.length - 1].produto;

                saborAtual.grupoAdics = [{
                    "id": "1",
                    "nome": "Ingredientes",
                    "embutido": "0",
                    "minimo": "1",
                    "maximo": "1",
                    "qtdeMult": "1",
                    "idRes": "1",
                    "grupro": "1",
                    "gruadi": "1",
                    "obrigatorio": "1",
                    "posicao": "1",
                    "adicionais" : []
                }];

                saborAtual.ingredientes = [];

                for (var n = 0; n < pedProd[i].INGREDIENTES.length; n++){

                    if (pedProd[i].INGREDIENTES[n].ADICIONA_RETIRA == 'A'){
                        saborAtual.grupoAdics[0].adicionais.push({   
                            "idAdicional" : "1",
                            "idGrupo"     : "1",
                            "codInt"      : "AAAA",
                            "nome"        : pedProd[i].INGREDIENTES[n].DESCRICAO,
                            "precod"      : "0.00",
                            "precor"      : "0.00",
                            "posicao"     : "1",
                            "cor"         : null,
                            "corfonte"    : null,
                            "checked"     : pedProd[i].INGREDIENTES[n].QUANTIDADE
                        });
                    }else{
                        saborAtual.ingredientes.push({
                            "idIngred"  : "",
                            "idProduto" : "",
                            "nome"      : pedProd[i].INGREDIENTES[n].DESCRICAO,
                            "entraDesc" : "1",
                            "permRetir" : "1",
                            "auxiliar"  : "",
                            "checked"   : true
                        });
                    }
                }
            }

            if (pedProd[i].MEIA.NOME.length > 0){

                tmpProduto.qtdeSabores = 2;

                tmpProduto.sabores.push({
                    "produto" : {
                        "idProduto": "",
                        "nomeProduto": pedProd[i].MEIA.NOME,
                        "unidade": pedProd[i].MEIA.UNIDADE,
                        "descricao": "",
                        "preco": "",
                        "promo": "0",
                        "permiteObs": "0",
                        "observacao" : pedProd[i].MEIA.OBSERVACOES
                    }
                });
    
                if (pedProd[i].MEIA.INGREDIENTES){
    
                    let saborAtual = tmpProduto.sabores[tmpProduto.sabores.length - 1].produto;
    
                    saborAtual.grupoAdics = [{
                        "id": "1",
                        "nome": "Ingredientes",
                        "embutido": "0",
                        "minimo": "1",
                        "maximo": "1",
                        "qtdeMult": "1",
                        "idRes": "1",
                        "grupro": "1",
                        "gruadi": "1",
                        "obrigatorio": "1",
                        "posicao": "1",
                        "adicionais" : []
                    }];
    
                    saborAtual.ingredientes = [];
    
                    for (var n = 0; n < pedProd[i].MEIA.INGREDIENTES.length; n++){
    
                        if (pedProd[i].MEIA.INGREDIENTES[n].ADICIONA_RETIRA == 'A'){
                            saborAtual.grupoAdics[0].adicionais.push({   
                                "idAdicional" : "1",
                                "idGrupo"     : "1",
                                "codInt"      : "AAAA",
                                "nome"        : pedProd[i].MEIA.INGREDIENTES[n].DESCRICAO,
                                "precod"      : "0.00",
                                "precor"      : "0.00",
                                "posicao"     : "1",
                                "cor"         : null,
                                "corfonte"    : null,
                                "checked"     : pedProd[i].MEIA.INGREDIENTES[n].QUANTIDADE
                            });
                        }else{
                            saborAtual.ingredientes.push({
                                "idIngred"  : "",
                                "idProduto" : "",
                                "nome"      : pedProd[i].MEIA.INGREDIENTES[n].DESCRICAO,
                                "entraDesc" : "1",
                                "permRetir" : "1",
                                "auxiliar"  : "",
                                "checked"   : true
                            });
                        }
                    }
                }
              
            }

            tmpCarrinho.push(tmpProduto);
        }

        return tmpCarrinho;
    }

    copiaPedidoCarrinhoV2 = async (axios, pedidoAtual) => {
        try {
            // here place your login logic     

            var CancelToken = axios.CancelToken;

            var parametros =
                "&partoken=" + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parpedido=" + pedidoAtual +
                "&parverapp=" + '99';

            var tmpCarrinho = new Array();
            var tmpIndisponivel = new Array();
            var tmpProduto  = [];


            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();

            }, global.timeOutTimer);


            return new Promise((resolve, reject) => {
                axios.post(
                    global.siteurl + 'pedido_consulta_produtos_v2.php',
                    parametros,
                    {
                        cancelToken: source.token,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        },
                        withCredentials: true,
                    }).then((response) => {
                        var responseData = response.data;
    
                        if (responseData.PEDIDOS.length > 0) {
    
                            let pedProd = responseData.PEDIDOS[0].PRODUTOS;
    
                            for (var i = 0; i < pedProd.length; i++) {
    
                                if ((pedProd[i].COPY_GRUPOPROD != '') && (pedProd[i].COPY_GRUPOPROD != undefined)) {
    
                                    tmpProduto = {
    
                                        chaveLocalStorage: "@vmx-001/cart",
                                        listaGruProdAtual: [],
                                        alterandoIndex: -1,
                                        qtde: 1,
                                        grupoProds: (pedProd[i].COPY_GRUPOPROD) ? pedProd[i].COPY_GRUPOPROD : '',
                                        grupoProdsCod: (pedProd[i].COPY_GRUPOPRODCOD) ? pedProd[i].COPY_GRUPOPRODCOD : '',
                                        grupoProdsMisto: (pedProd[i].COPY_GRUPOPRODMIS) ? pedProd[i].COPY_GRUPOPRODMIS : '',
                                        qtdeSabores: 0,
                                        saborAtual: 0,
                                        sabores: [],
                                        valorUnit: 0,
                                        grupoAdics: [],
                                        qtdeSabores: 1,
                                        adicIndisp: pedProd[i].ADIC_INDISP
                                    };
    
                                    tmpProduto.grupoProds = (pedProd[i].COPY_GRUPOPROD) ? pedProd[i].COPY_GRUPOPROD : '';
                                    tmpProduto.grupoProdsCod = (pedProd[i].COPY_GRUPOPRODCOD) ? pedProd[i].COPY_GRUPOPRODCOD : '';
                                    tmpProduto.grupoProdsMisto = (pedProd[i].COPY_GRUPOPRODMIS) ? pedProd[i].COPY_GRUPOPRODMIS : '';
    
                                    tmpProduto.qtde = parseFloat(pedProd[i].QUANTIDADE);
                                    tmpProduto.valorUnit = global.ProductObj.convertValueToStr(pedProd[i].VALOR_UNITARIO);
    
                                    tmpProduto.grupoProdsFideGera = pedProd[i].fide_beneficios;
                                    tmpProduto.grupoProdsFideResg = pedProd[i].fide_resgate;
    
                                    tmpProduto.sabores.push({
                                        "produto": {
                                            "idProduto": pedProd[i].CODIGO_PRODUTO,
                                            "nomeProduto": pedProd[i].NOME,
                                            "descricao": "",
                                            "preco": pedProd[i].COPY_VALOR,
                                            "promo": "0",
                                            "permiteObs": pedProd[i].COPY_PEROBS,
                                            "unidade": pedProd[i].UNIDADE,
                                            "unidadeMin": pedProd[i].COPY_UNIDADEMIN,
                                            "unidadeMul": pedProd[i].COPY_UNIDADEMUL,
                                            "observacao": (pedProd[i].COPY_PEROBS == '1') ? pedProd[i].OBSERVACOES : ''
                                        }
                                    });
    
                                    if (pedProd[i].INGREDIENTES) {
    
                                        let saborAtual = tmpProduto.sabores[tmpProduto.sabores.length - 1].produto;
    
                                        saborAtual.grupoAdics = [];
                                        tmpProduto.grupoAdics = [];
    
                                        pedProd[i].GRUPO_ADICS.map( (grupoAdicAtual, grupoAdicIndex) => {
                                            if (grupoAdicAtual.embutido == '1'){
                                                tmpProduto.grupoAdics.push(JSON.parse(JSON.stringify(grupoAdicAtual)));
                                            }else{
                                                saborAtual.grupoAdics.push(JSON.parse(JSON.stringify(grupoAdicAtual)));
                                            }
                                        });
    
                                        saborAtual.ingredientes_tmp = pedProd[i].INGREDIENTES;
                                        saborAtual.retira_ingr      = pedProd[i].RETIRA_ING;
                                        saborAtual.ingredientes     = [];

                                        tmpProduto.ingredientes_tmp = pedProd[i].INGREDIENTES;
                                        tmpProduto.retira_ingr      = pedProd[i].RETIRA_ING;
                                        tmpProduto.ingredientes     = [];                                        

                                        this.mergeAdicionais(tmpProduto);
                                        this.mergeAdicionais(saborAtual);
                                    }
    
                                    if (pedProd[i].MEIA.NOME?.length > 0) {
    
                                        tmpProduto.qtdeSabores = 2;
    
                                        tmpProduto.sabores.push({
                                            "produto": {
                                                "idProduto": pedProd[i].MEIA.CODIGO_PRODUTO,
                                                "nomeProduto": pedProd[i].MEIA.NOME,
                                                "descricao": "",
                                                "preco": pedProd[i].MEIA.COPY_VALOR,
                                                "promo": "0",
                                                "permiteObs": pedProd[i].MEIA.COPY_PEROBS,
                                                "unidade": pedProd[i].MEIA.UNIDADE,
                                                "unidadeMin": pedProd[i].MEIA.COPY_UNIDADEMIN,
                                                "unidadeMul": pedProd[i].MEIA.COPY_UNIDADEMUL,
                                                "observacao": (pedProd[i].MEIA.COPY_PEROBS == '1') ? pedProd[i].MEIA.OBSERVACOES : ''
                                            }
                                        });
    
                                        if (pedProd[i].MEIA.INGREDIENTES) {
    
                                            let saborAtual = tmpProduto.sabores[tmpProduto.sabores.length - 1].produto;

                                            saborAtual.grupoAdics = [];
        
                                            pedProd[i].GRUPO_ADICS.map( (grupoAdicAtual, grupoAdicIndex) => {
                                                if (grupoAdicAtual.embutido == '1'){
                                                    //tmpProduto.grupoAdics.push(grupoAdicAtual);
                                                }else{
                                                    saborAtual.grupoAdics.push(JSON.parse(JSON.stringify(grupoAdicAtual)));
                                                }
                                            });
    
                                            saborAtual.ingredientes_tmp = pedProd[i].MEIA.INGREDIENTES;
                                            saborAtual.retira_ingr      = pedProd[i].MEIA.RETIRA_ING;
                                            saborAtual.ingredientes     = [];

                                            this.mergeAdicionais(saborAtual);
                                        }
    
                                    }
    
                                    if (pedProd[i].INDISPONIVEL) {
    
                                        var item = tmpProduto;
                                        var saboresNome = "";
                                        item.sabores.map((value, index) => {
    
                                            var prod = value.produto;
    
                                            saboresNome += (saboresNome.length > 0) ? ' ' : '';
    
                                            saboresNome += ((item.qtdeSabores > 1) ? '1/' + item.qtdeSabores + ' ' : '') + prod.nomeProduto;
                                        });
                                        tmpIndisponivel.push(saboresNome);
                                    } else {
    
                                        tmpProduto.checkedFidelidade = 0;  
                                        tmpProduto.grupoProdsFideGera = pedProd[i].fide_beneficios;
                                        tmpProduto.grupoProdsFideResg = pedProd[i].fide_resgate;
    
                                        tmpCarrinho.push(tmpProduto);
    
                                        if (tmpProduto.adicIndisp) {
                                            tmpProduto.adicIndisp.map((value, index) => {
                                                tmpIndisponivel.push(value.DESCRICAO);
                                            });
                                        }
                                    }
    
                                }
    
                            }
    
                            var newCarrinho = tmpCarrinho;
                            let stringCart = JSON.stringify(newCarrinho);
    
                            global.ProductObj.limpaCarrinho();
                            localStorage.setItem(global.ProductObj.chaveLocalStorage, stringCart);
    
                            responseData.carrinhoTamanho = tmpCarrinho.length;
                            resolve(responseData);
                            //this.leProdutosDisponiveis(tmpProd);
    
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((error) => {
    
                        resolve(false);
                        
                    });
            });

        } catch (err) {
            return false;
        }
    }

    copiaPedidoCarrinho = (pedProd, prodsDisponiveis) => {

        var resultadoFunc = this.jsonProdPedToProductCarrinho(pedProd, prodsDisponiveis);
        var newCarrinho = resultadoFunc.disponivel;
        let stringCart = JSON.stringify(newCarrinho);

        this.limpaCarrinho();
        localStorage.setItem(global.ProductObj.chaveLocalStorage, stringCart);

        return {
            indisp : resultadoFunc.indisp,
            dispQtd : resultadoFunc.disponivel.length
        };

    }
}