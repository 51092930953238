import React, { useState, useEffect } from "react";
import classNames from "classnames";
// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import ArrowBack  from "@mui/icons-material/ArrowBack";
import Add  from "@mui/icons-material/Add";
import Remove  from "@mui/icons-material/Remove";
import Cached  from "@mui/icons-material/Cached";
// @material-ui/colors
import { green } from '@mui/material/colors';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Confirmacao from "components/Dialogs/Confirmacao.js";

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import styles from "assets/jss/material-kit-react/views/principalPage.js";
import  "assets/css/PrincipalPage.css";

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";

import 'prevent-pull-refresh';

import axios from 'axios';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, TextField } from "@mui/material";
import { BS_ALE, geraHorarios } from "funcs/MyFuncs";

const delayProcessando     = 0;
const tempoMinProcess      = 0;

const corCheckBoxRemove = '#980000';
const corCheckBoxAdiciona = '#1c6627';

const RemoveCheckbox = withStyles((props) => <Checkbox color="default" {...props} />, {
    root: {
        color: corCheckBoxRemove,
      '&$checked': {
        color: corCheckBoxRemove,
      },
      [`& label`]: {
        color: corCheckBoxRemove,
      },
    },
    checked: {},
  });

const AdicionaCheckbox = withStyles((props) => <Checkbox color="default" {...props} />, {
    root: {
        color: corCheckBoxAdiciona,
      '&$checked': {
        color: corCheckBoxAdiciona,
      },
      [`& label`]: {
        color: corCheckBoxAdiciona,
      },
    },
    checked: {},
  });
  

class SelecionaProd_selecionaSabor extends React.Component {

    arrPosItems  = [];    
    arrMenuItems = [];
    cardBody     = null;
    myMenu       = null;
    cabecalho    = null;
    myScroller   = null;

    ultElementMenu = -1;

    fecharPesquisa = 0;

    constructor(props) {
        super(props);
        // Não chame this.setState() aqui!

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        let myRestID = localStorage.getItem("@vmx-001/rest-id");
        if (!myRestID) {
            myRestID = "";
        }

        this.state = { 
            pesquisaTxt : global.ProductObj.pesquisaProd,
            listaGruPro: [], usr_id: 12, usr_grures: 6, scrollMarginTop: (global.ProductObj.pesquisaProd.length > 0) ? 125 : 75,
            grupos_prods: [null, null, null, null, null, null, null, null],
            nomeRest: "",
            restID: "",
            grupoRest: "",
            promocao: "0",
            tmpQtdProd: global.ProductObj.produto.qtde,
            itemAtivo: 0,
            posItemClicado: -1,
            eventClicado: -1,
            carrinhoCompra: localCart,
            restAtual: myRestID,
            qtdeProd: 1,
            prod_obs: "",
            mostraTituloAlt: false,

            alertaNewSabor: false,
            tmpNewSabor: [],

            imgDetalhes : null,
        };

    }

    isScrolledIntoView = (elem) => {
        var docViewTop = this.props.posicaoScroll;
        var docViewBottom = docViewTop + window.innerHeight - 50;

        var elemTop = elem.offsetTop;
        var elemBottom = elemTop + elem.clientHeight;

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    setScrollToTop = () => {

        window.scrollTo(0, 0);
        
    }

    listenScrollEvent = () => {

        var resultado = false;
        if (this.divTotalProd){
            resultado = !this.isScrolledIntoView(this.divTotalProd);
        }

        this.setState({
            mostraTituloAlt : resultado
        });
        
    }

    componentDidMount = () => {

        var parametros = 'parresid='  + this.state.usr_id + "&parverapp=" + 23;
        this.setState({ 'erroDB' : false });

        try{
            
            window.addEventListener('scroll', this.listenScrollEvent.bind(this));

            this.handleLoad();

        }catch (err){
        //BS_ALE(this,  '', 'Não foi possível atualizar as informações, tente novamente mais tarde', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            this.setState({ 'erroDB' : true });
        } 
        //this.verificaAtt();
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props
        if(oldProps.posicaoScroll !== newProps.posicaoScroll) {
            var resultado = false;
            if (this.divTotalProd){
                resultado = !this.isScrolledIntoView(this.divTotalProd);
            }

            this.setState({
                mostraTituloAlt : resultado
            });
        }
    }

    componentWillUnmount = () => {
        
    }
    
    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = alturaItems / (alturaScroll);

        var tmpPos = 0;
        var itemAtual = 0;

        for (var i = 0; i < this.arrPosItems.length; i++){
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ( (scrolledY * proporcao) < ( ( (this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop) ){
                break;
            }else{
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }        

        if (this.state.posItemClicado == -1){
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }else if (scrolledY == this.state.posItemClicado){

            if (this.state.eventClicado == -1){
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual});
                }
    
                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
                this.setState({ 
                    posItemClicado: -1,
                });
            }else{
                this.myMenu.scrollTo({left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth'})
                this.setState({ 
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado : -1,
                });
            }
        }else{
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});
            }
        }


    }

    atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {
        
        try {
    
            var CancelToken = axios.CancelToken;
    
            let source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
            }, 20000);
    
            
            await axios.post(
              global.urlServer + metodo, 
              parametros,
              {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                }
              }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == '1') {
                  arrLista = JSON.parse(JSON.stringify(responseData.registros));
                  
                  var arrFinal = [];
    
                  if (arrLista){
                    arrLista.map((prop, key) => {
                      arrFinal.push({
                        key : key, 
                        codInterno : prop.idGrupo, 
                        nome : prop.nome, 
                        precoDelivery : prop.posicao, 
                        descricao : global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                        image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                      });
                    });
                  }
                  
                  this.setState({
                    listaGruPro: arrFinal,
                  });
                }else{
                  this.setState({
                    erroDB : true,
                  });
                }
            })
            .catch((error) =>{
              if (error == '785936'){
    
              }else if(axios.isCancel(error)){
    
              }else{
            
    
              }
            });
        } catch (err) {
          
        }    
      } 

    clickProd(item){
        alert('Hello: ' + item.nome);
    }

    alteraSaborProdutoConfirmado(){
        global.ProductObj.changeSaborAtual();
        this.setState({
            refresh : 1    
        });
    }
    
    alteraSaborProduto(){
        this.setState({
            alertaNewSabor : true    
        });
    }

    scrollToItem = (index) => {

        var offsetNextScroll = 80;

        var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / alturaScroll;

        var posicaoItem  = 0;

        this.arrPosItems.map((item, itemIndex) => {
            if (itemIndex == index){
                posicaoItem = (item) ? ( (item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop ) : 0;
            }
        });

        /*for (var i = 0; i < (index); i++){
            var tmpItem = this.arrPosItems[i];
            posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
        }

        posicaoItem -= this.state.scrollMarginTop;
        posicaoItem += this.cabecalho.clientHeight;
        posicaoItem = posicaoItem / proporcao;
        posicaoItem += offsetNextScroll;
        posicaoItem = Math.round(posicaoItem);*/

        window.scrollTo({top: posicaoItem, behavior: 'smooth'});

        this.setState({
            posItemClicado : posicaoItem,
            eventClicado   : index,
        });
        
    }

    renderMenu = (items) => {
        const { classes } = this.props;
        var resultado = null;
        
        var qtdeSabores = '';
        if (global.ProductObj.produto.grupoProdsMisto > 1){
            qtdeSabores = (global.ProductObj.produto.qtdeSabores == 1) ? " - 1 sabor" : " - 2 sabores";
        }        

        var precoAtual = global.ProductObj.produto.valorUnit; 

        var strValorTotal = (precoAtual * this.state.tmpQtdProd).toFixed(2).replace('.', ',');
        
        var showTituloAlt = this.state.mostraTituloAlt;

        return (
            <div style={{flexGrow: 1, display:'flex', flexDirection: 'row', justifyContent:'center', alignItems: 'center', fontSize: '20px', color:( (showTituloAlt) ? '#fff' : '#000' )}}>
                <IconButton
                color="inherit"
                style={{padding: '7px'}}
                onClick={() => {
                    this.resolveBackButton();
                }}
                >
                  <ArrowBack />
                </IconButton>
                <div style={
                        (showTituloAlt) ?   {fontWeight: '500', marginLeft: 'auto', marginRight: '10px'} :
                                            {}
                    }>
                    {(showTituloAlt) ?
                        (<div style={{width: 'max-content'}}>{'Valor: R$ ' + strValorTotal}</div>) 
                        : (global.ProductObj.produto.grupoProds + qtdeSabores)}
                </div>
                
            </div>
        );

    }

    handleCloseAlerta = () => {
        this.setState({
            alertaNewSabor: false
        });
    }

    verificaProdAdic = (prod) => {
        if (global.ProductObj.getSaborAtual().mudando && ( (global.ProductObj.getSaborAtual().produto.idProduto != prod.idProduto))){
            this.setState({
                tmpNewSabor : prod
            }, () => {
                this.mudaProduto();
            });
            
        }else{
            this.fecharPesquisa += 1;
            global.ProductObj.setSabor(prod);
            this.setState({
                qtde : 1, 
                pesquisaAberta: false,
                pesquisaTxt: '', 
            });
        }
    }

    selectProd = (prod) => {
        let cartCopy = [...this.state.carrinhoCompra];

        //idProduto
        let {idProduto} = prod;

        let existingItem = cartCopy.find(cartItem => cartItem.idProduto == idProduto);
  
        //if item already exists
        if (existingItem) {
            existingItem.qtde += 1 //update item
        } else { //if item doesn't exist, simply add it
            cartCopy.push({
                nome: prod.nomeProduto,
                qtde: 1,
                valorTotal: prod.preco,
                idProduto: prod.idProduto,
            });
        }

        

        this.setState({
            carrinhoCompra: cartCopy
        });

        //make cart a string and store in local space
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem(global.ProductObj.chaveLocalStorage, stringCart);
    }

    setQtdeSabor = (qtde) => {
        global.ProductObj.setOnlyQtdeSabores(qtde);

        this.setState({
            refresh : true,
        });
    }

    renderQtdeSabores = (grupoProd) => {
        const { classes } = this.props;

        if (grupoProd){

            return grupoProd.map( (value, index) => {

                if (value) {
                    return (
                        <div>
                            <div onClick={(e) => this.setQtdeSabor(1)}>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                                    <div>
                                        <div>
                                            <text style={{fontSize:'18px', fontWeight:'bold'}}>{value.nome} - 1 Sabor</text>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                            </div>
                            <div onClick={(e) => this.setQtdeSabor(2)}>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                                    <div>
                                        <div>
                                            <text style={{fontSize:'18px', fontWeight:'bold'}}>{value.nome} - 2 Sabores</text>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                            </div>
                        </div>
                    );
                }
                
            });
            
        }
        
    }

    renderImageProd = (cod_restaurante, imagem) => {
        if (imagem){
            if (imagem.length > 0){
                return (
                    <div onClick={() => { this.setState({ imgDetalhes : global.siteurl + "rests/" + cod_restaurante + "/imgs/prods/" + imagem + ".png"}) }} style={{flex: 1, minWidth: '130px', display: "flex", justifyContent: "flex-end"}}>
                        <img src={global.siteurl + "rests/" + cod_restaurante + "/imgs/prods/" + imagem + ".png"} style={{width: '105px', height: "70px", margin: 'auto 0px'}} onError={(e)=>{
                                e.target.onerror = null; 
                                e.target.style.visibility = 'hidden';
                            }}></img>
                    </div>
                );
            }
        }
    }

    renderProds = (prodArrFinal) => {
        const { classes } = this.props;

        return prodArrFinal.map((prod, index) => {

            if ( (prod.promo == '1') & (prod.mistoPromo == '0') & (global.ProductObj.produto.qtdeSabores > 1) ){
                return false;
            }

            return (
                <div onClick={() => { this.verificaProdAdic(prod) }}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>{prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' )}</text>
                            </div>
                            <div>
                                <text style={{display: 'inline-block', fontSize:'13px', lineHeight: '18px'}}>{prod.descricao}</text>
                            </div>  
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold', color: '#777'}}>R$ {prod.preco.replace('.', ',')}</text>
                            </div>                 
                        </div>
                        {this.renderImageProd(global.cod_restaurante, prod.imagem)}
                    </div>    
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            );
        });
    }

    verificaGrupoProd = ( grupoProd, prodArrFinal ) => {
        return this.renderProds(prodArrFinal);
    }
    
    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            if (item == null){
                return (
                    <div className={"my-loading"}  ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px', height: '150px'}}></div>
                ); // my-loading
            }

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                this.ultElementMenu = index;
                return (
                    <div ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px'}}>
                        <div>
                            {this.verificaGrupoProd(item, prodArrFinal)}
                        </div>
                    </div>
                );
            }else{
                return (
                    <div ref={(ref) => this.arrPosItems[index] = ref} >
                        
                    </div>
                );
            }

            /*return (
                <ListItem style={{scrollMarginTop: this.state.scrollMarginTop, }} key={index} ref={(ref) => this.arrPosItems.push(ref)} onClick={() => this.clickProd(item)} alignItems="flex-start">
                    <ListItemAvatar> 
                        <Avatar variant="square" imgProps={{width: '100%', height: 'unset'}} src={item.image} classes={{ img: classes.myAvatarImg }} className={classes.myImgProds} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.nome}
                        secondary={
                            <React.Fragment>
                                {this.renderProds(item)}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            );*/
        });
    }

    pesquisaLista = (value) => {
        if (this.props.scrollFunction){
            this.props.scrollFunction();
        }
        
        global.ProductObj.pesquisaProd = value;
        this.setState({pesquisaTxt: value}, () => {

        });
    }

    abrePesquisa = (pesquisaAberta) => {
        if (pesquisaAberta){
            this.setState({
                scrollMarginTop: 125
            });
        }else{
            this.setState({
                scrollMarginTop: 75
            });
        }        
    }

    comparaTexto(val1, val2){

        var arrPalavras = val2.split(' ');
    
        var resultado = true;
        arrPalavras.map((palavra, index) => {
          if (!this.trataTexto(val1).includes(this.trataTexto(palavra))){
            resultado = false;
          }
        });
    
        return resultado;
    }

    trataTexto(val){
        return (
            val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        );
    }

    handleLoad() {
        var menuItems = [];
    
        try {
            this.setState({
                grupos_prods: global.ProductObj.produto.listaGruProdAtual,    
            });
        }catch{

        }
    
    
        
      }

    
    renderSelecionaSabor = () => {

        const { classes } = this.props;

        return (
            <List ref={(ref) => this.cardBody = ref} className={classes.root}>
                <List className={classes.root}>{this.renderLista(this.state.grupos_prods)}</List>
            </List>
        );
    }

    clickChangeQtdeProd = (somar) => {
        var tmpQtde = global.ProductObj.produto.qtde;
        
        var saborAtual = global.ProductObj.getSaborAtual().produto; 

        var qtdeMudanca = (saborAtual.unidade == "KG") ? parseFloat(saborAtual.unidadeMul) : 1;

        var qtdeMinima  = (saborAtual.unidade == "KG") ? parseFloat(saborAtual.unidadeMin) : 1;

        if (somar){
            tmpQtde = tmpQtde + qtdeMudanca;
        }else{
            tmpQtde = tmpQtde - qtdeMudanca;
        }

        tmpQtde = (tmpQtde <  qtdeMinima) ?  qtdeMinima : tmpQtde;
        tmpQtde = (tmpQtde > 99) ? 99 : tmpQtde;

        tmpQtde = Math.round((tmpQtde + Number.EPSILON) * 1000) / 1000;

        global.ProductObj.produto.qtde = tmpQtde;

        this.setState({
            tmpQtdProd : tmpQtde
        });
    }

    addAdicoes = (event) => {
        var posicao   = event.currentTarget.value;
        if (global.ProductObj.addSaborAdicoes(posicao)){
            this.setState({
                reload: true,
            });
        }else{
            
        }
    };

    removeAdicoes = (event) => {
        var posicao   = event.currentTarget.value;
        if (global.ProductObj.removeSaborAdicoes(posicao)){
            this.setState({
                reload: true,
            });
        }else{
            
        }
    };

    addEmbutidos = (event) => {
        var posicao   = event.currentTarget.value;
        if (global.ProductObj.addSaborEmbutidos(posicao)){
            this.setState({
                reload: true,
            });
        }else{
            
        }
    };

    removeEmbutidos = (event) => {
        var posicao   = event.currentTarget.value;
        if (global.ProductObj.removeSaborEmbutidos(posicao)){
            this.setState({
                reload: true,
            });
        }else{
            
        }
    };

    handleChangeRemocoes = (event) => {
        var posicao   = event.target.value;
        global.ProductObj.changeSaborRemocoes(posicao);
        this.setState({
            reload: true,
        });
    };

    handleChangeAdicoes = (event) => {
        var posicao   = event.target.value;
        if (global.ProductObj.changeSaborAdicoes(posicao)){
            this.setState({
                reload: true,
            });
        }else{
            
        }
    };

    handleChangeEmbutidos = (event) => {
        var posicao   = event.target.value;
        if (global.ProductObj.changeEmbutidos(posicao)){
            this.setState({
                reload: true,
            });
        }else{
            
        }
    };

    handleChangeObs = (event) => {
        var obsTXT = event.target.value;
        global.ProductObj.setSaborObs(obsTXT);
        this.setState({
            [event.target.name]: obsTXT,
        });
    };

    renderObs = (prod) => {
        const { classes } = this.props;

        var obsSaborAtual = global.ProductObj.getSaborAtual().produto.observacao;

        if (!obsSaborAtual){
            obsSaborAtual = "";
        }

        if (prod.permiteObs == "1"){
            return (
                <div style={{width: '100%',}}>
                    <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                        <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Observações</div>
                        <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>{global.exObs}</div>
                    </div>
                    <div style={{width: '100%', padding: '10px'}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="prod_obs"
                            label="Observação"
                            name="prod_obs"
                            value={obsSaborAtual}
                            onChange={this.handleChangeObs}
                        />
                    </div>
                </div>
            );
        }
    }

    renderEmbutidos = (prod) => {

        const { classes } = this.props;

        var adicoes = new Array();

        global.ProductObj.produto.grupoAdics.map( (value, index) => {
            adicoes.push({...value, myKey: index});
        });

        if (adicoes){
            return adicoes.map( (value, index) => {

                const palavraItem = (value.maximo > 1) ? "itens" : "item";

                var textoEscolha = "";

                var posGruAdic = index;
            
                if ((value.minimo <= 0) && (value.maximo <= 0)) {
                    textoEscolha = "Escolha quantos itens desejar";
                }else if ((value.minimo <= 0) && (value.maximo > 0)) {
                    textoEscolha = "Escolha até " + value.maximo.toString() + " " + palavraItem;
                }else if ((value.minimo > 0) && (value.maximo == value.minimo)) {
                    textoEscolha = "Escolha " + value.minimo.toString() + " " + palavraItem;
                }else if ((value.minimo > 0) && (value.maximo > value.minimo)) {
                    textoEscolha = "Escolha entre " + value.minimo.toString() + " e " + value.maximo.toString() + " itens";
                }

                if (value.qtdeMult == 1){
                    return this.renderEmbutidosCheck(value, index, textoEscolha);
                }else{
                    return this.renderEmbutidosQtd(value, index, textoEscolha);
                }

                return (
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div style={{width: '100%',}}>
                            <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                                <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Adicionar - {value.nome}</div>
                                <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>{textoEscolha}</div>
                            </div>
                            <div style={{width: '100%', padding: '10px'}}>
                            {
                                value.adicionais.map( (value, index) => {
                                    return (
                                        <div style={{paddingTop: '10px'}}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <FormControlLabel
                                                    control={<AdicionaCheckbox checked={(value.checked >= 1) ? true : false} value={posGruAdic + "." + index} onChange={this.handleChangeEmbutidos} name="checkedG" dataKey={value.myKey} />}
                                                    label={value.nome} style={{color: corCheckBoxAdiciona, width: '100%', height: '100%',}}
                                                />
                                                <div style={{minWidth: '100px', margin: 'auto', marginRight: '0'}}>
                                                    {this.renderPrecoAdic(value.precod)}
                                                </div>
                                            </div>
                                            <div style={{marginTop: '10px',}} className={classes.separator}></div> 
                                        </div>
                                    )
                                })
                            }  
                            </div>
                                
                        </div>                            
                    </div> 
                );
            });
        }

    }

    renderEmbutidosCheck = (value, posGruAdic, textoEscolha) => {

        const { classes } = this.props;

        return (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                <div style={{width: '100%',}}>
                    <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                        <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Adicionar - {value.nome}</div>
                        <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>{textoEscolha}</div>
                    </div>
                    <div style={{width: '100%', padding: '10px'}}>
                    {
                        value.adicionais.map( (value, index) => {
                            return (
                                <div style={{paddingTop: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <FormControlLabel
                                            control={<AdicionaCheckbox checked={(value.checked >= 1) ? true : false} value={posGruAdic + "." + index} onChange={this.handleChangeEmbutidos} name="checkedG" dataKey={value.myKey} />}
                                            label={value.nome} style={{color: corCheckBoxAdiciona, width: '100%', height: '100%',}}
                                        />
                                        <div style={{minWidth: '100px', margin: 'auto', marginRight: '0'}}>
                                            {this.renderPrecoAdic(value.precod)}
                                        </div>
                                    </div>
                                    <div style={{marginTop: '10px',}} className={classes.separator}></div> 
                                </div>
                            )
                        })
                    }  
                    </div>
                        
                </div>                            
            </div>
        );
    }

    renderEmbutidosQtd = (value, posGruAdic, textoEscolha) => {

        const { classes } = this.props;

        return (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                <div style={{width: '100%',}}>
                    <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                        <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Adicionar - {value.nome}</div>
                        <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>{textoEscolha}</div>
                    </div>
                    <div style={{width: '100%', padding: '10px'}}>
                    {
                        value.adicionais.map( (value, index) => {

                            var tipoCalcAdicional = global.tipoCalcPrecoAdicional;
                            var precoAdic = (tipoCalcAdicional === '0') ? ((global.ProductObj.produto.qtdeSabores > 1) ? value.precod : value.precod * 2) : value.precod; 

                            return (
                                <div style={{paddingTop: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{marginLeft: 'auto', minWidth: 'fit-content', marginRight: '0px'}}>
                                            <IconButton
                                                style={{color: '#ba2d20'}}
                                                aria-label="open drawer"
                                                value={posGruAdic + "." + index}
                                                onClick={this.removeEmbutidos}
                                            >
                                                <Remove />
                                            </IconButton>
                                            <text style={{fontSize:'15px', fontWeight:'bold', marginBottom: 'auto', marginTop: 'auto'}}>{(value.checked) ? value.checked : 0}</text>
                                            <IconButton
                                                aria-label="open drawer"
                                                style={{color: '#339119'}}
                                                value={posGruAdic + "." + index}
                                                onClick={this.addEmbutidos}
                                            >
                                                <Add />
                                            </IconButton>
                                        </div>
                                        <div style={{width: '70%', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '12px', fontSize: '16px', fontWeight: '400'}}>
                                            {value.nome}
                                        </div>
                                        <div style={{minWidth: '100px', margin: 'auto', marginRight: '0'}}>
                                            {this.renderPrecoAdic(precoAdic)}
                                        </div>
                                    </div>
                                    <div style={{marginTop: '10px',}} className={classes.separator}></div> 
                                </div>
                            )
                        })
                    }  
                    </div>
                        
                </div>                            
            </div> 
        );
    } 

    renderAdicoesQtd = (value, posGruAdic, textoEscolha) => {

        const { classes } = this.props;

        return (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                <div style={{width: '100%',}}>
                    <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                        <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Adicionar - {value.nome}</div>
                        <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>{textoEscolha}</div>
                    </div>
                    <div style={{width: '100%', padding: '10px'}}>
                    {
                        value.adicionais.map( (value, index) => {

                            var tipoCalcAdicional = global.tipoCalcPrecoAdicional;
                            var precoAdic = (tipoCalcAdicional === '0') ? ((global.ProductObj.produto.qtdeSabores > 1) ? value.precod : value.precod * 2) : value.precod; 

                            return (
                                <div style={{paddingTop: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{marginLeft: 'auto', minWidth: 'fit-content', marginRight: '0px'}}>
                                            <IconButton
                                                style={{color: '#ba2d20'}}
                                                aria-label="open drawer"
                                                value={posGruAdic + "." + index}
                                                onClick={this.removeAdicoes}
                                            >
                                                <Remove />
                                            </IconButton>
                                            <text style={{fontSize:'15px', fontWeight:'bold', marginBottom: 'auto', marginTop: 'auto'}}>{(value.checked) ? value.checked : 0}</text>
                                            <IconButton
                                                aria-label="open drawer"
                                                style={{color: '#339119'}}
                                                value={posGruAdic + "." + index}
                                                onClick={this.addAdicoes}
                                            >
                                                <Add />
                                            </IconButton>
                                        </div>
                                        <div style={{width: '70%', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '12px', fontSize: '16px', fontWeight: '400'}}>
                                            {value.nome}
                                        </div>
                                        <div style={{minWidth: '100px', margin: 'auto', marginRight: '0'}}>
                                            {this.renderPrecoAdic(precoAdic)}
                                        </div>
                                    </div>
                                    <div style={{marginTop: '10px',}} className={classes.separator}></div> 
                                </div>
                            )
                        })
                    }  
                    </div>
                        
                </div>                            
            </div> 
        );
    } 

    renderPrecoAdic = (preco) => {

        var precoFloat = 0;
        
        try {
            precoFloat = parseFloat( preco.replace(',', '.').trim() );
        } catch (error) {
            precoFloat = 0;
        }
        
        if (precoFloat > 0){
            return (
                <div style={{marginLeft: 'auto', marginRight: '0', width: 'fit-content', fontSize: '1rem', color:corCheckBoxAdiciona}}>R$ {preco.replace('.', ',')}</div>
            );
        }
    }

    renderAdicoesCheck = (value, posGruAdic, textoEscolha) => {

        const { classes } = this.props;

        return (
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                <div style={{width: '100%',}}>
                    <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                        <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Adicionar - {value.nome}</div>
                        <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>{textoEscolha}</div>
                    </div>
                    <div style={{width: '100%', padding: '10px'}}>
                    {
                        value.adicionais.map( (value, index) => {

                            var tipoCalcAdicional = global.tipoCalcPrecoAdicional;
                            var precoAdic = (tipoCalcAdicional === '0') ? ( (global.ProductObj.produto.qtdeSabores > 1) ? value.precod : (value.precod * 2).toFixed(2) ) : value.precod; 

                            return (
                                <div style={{paddingTop: '10px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <FormControlLabel
                                            control={<AdicionaCheckbox checked={(value.checked >= 1) ? true : false} value={posGruAdic + "." + index} onChange={this.handleChangeAdicoes} name="checkedG" dataKey={value.myKey} />}
                                            label={value.nome} style={{color: corCheckBoxAdiciona, width: '100%', height: '100%',}}
                                        />
                                        <div style={{minWidth: '100px', margin: 'auto', marginRight: '0'}}>
                                            {this.renderPrecoAdic(precoAdic)}
                                        </div>
                                    </div>
                                    <div style={{marginTop: '10px',}} className={classes.separator}></div> 
                                </div>
                            )
                        })
                    }  
                    </div>
                        
                </div>                            
            </div> 
        );
    }

    renderAdicoes = (prod) => {
        const { classes } = this.props;

        var adicoes = new Array();

        prod.grupoAdics.map( (value, index) => {
            adicoes.push({...value, myKey: index});
        });

        if (adicoes){
            return adicoes.map( (value, index) => {

                const palavraItem = (value.maximo > 1) ? "itens" : "item";

                var textoEscolha = "";

                var posGruAdic = index;
            
                if ((value.minimo <= 0) && (value.maximo <= 0)) {
                    textoEscolha = "Escolha quantos itens desejar";
                }else if ((value.minimo <= 0) && (value.maximo > 0)) {
                    textoEscolha = "Escolha até " + value.maximo.toString() + " " + palavraItem;
                }else if ((value.minimo > 0) && (value.maximo == value.minimo)) {
                    textoEscolha = "Escolha " + value.minimo.toString() + " " + palavraItem;
                }else if ((value.minimo > 0) && (value.maximo > value.minimo)) {
                    textoEscolha = "Escolha entre " + value.minimo.toString() + " e " + value.maximo.toString() + " itens";
                }

                if (value.qtdeMult == 1){
                    return this.renderAdicoesCheck(value, index, textoEscolha);
                }else{
                    return this.renderAdicoesQtd(value, index, textoEscolha);
                }
            });
        }
    }

    renderRemocoes = (prod) => {

        const { classes } = this.props;

        var remocoes = new Array();

        prod.ingredientes.map( (value, index) => {
            if (value.permRetir == "1"){
                remocoes.push({...value, myKey: index});
            }
        });

        if (remocoes){
            if (remocoes.length > 0){
                return (
                    <div style={{width: '100%',}}>
                        <div style={{backgroundColor: '#eee', width: '100%', padding: '10px'}}>
                            <div style={{fontSize:'16px', color: '#000', fontWeight: '500'}}>Se deseja retirar algum ingrediente, selecione abaixo.</div>
                            <div style={{fontSize:'15px', color: '#777', fontWeight: '410'}}>Escolha quantos itens desejar</div>
                        </div>
                        <div style={{width: '100%', padding: '10px'}}>
                        {
                            remocoes.map( (value, index) => {
                                return (
                                    <div style={{paddingTop: '10px'}}>
                                        <FormControlLabel
                                            control={<RemoveCheckbox checked={(value.checked) ? true : false} value={value.myKey} onChange={this.handleChangeRemocoes} name="checkedG" dataKey={value.myKey} />}
                                            label={value.nome} style={{color: corCheckBoxRemove, width: '100%', height: '100%',}}
                                        />
                                        <div style={{marginTop: '10px'}} className={classes.separator}></div> 
                                    </div>
                                )
                            })
                        }  
                        </div>
                            
                    </div>
                );
            }
        }

    }

    renderSelecaoSeparator = (index) => {

        const { classes } = this.props;

        if ((global.ProductObj.produto.saborAtual == index)) {
            return (<div style={{marginTop: '4px', backgroundColor: '#f00'}} className={classes.separatorMenu}></div>)
        } 
    }

    changeSaborAtual = (index) => {

        if (global.ProductObj.verificaAdicionais()){
            global.ProductObj.setSaborAtual(index);

            this.setState({
                newState : index
            });
        }else{
            BS_ALE(this, 'Atenção', 'Verifique os adicionais obrigatórios');
        } 
    }

    renderSelecaoProd = () => {

        const { classes } = this.props;

        if (global.ProductObj.produto.qtdeSabores > 1){
            return (
                <div style={{width: '100%'}}>
                    <div>
                        <div style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => this.changeSaborAtual(0)} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{'1º Sabor'}</a>
                            {this.renderSelecaoSeparator(0)}
                        </div> 
                        <div style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => this.changeSaborAtual(1)} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{'2º Sabor'}</a>
                            {this.renderSelecaoSeparator(1)}
                        </div> 
                    </div>
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>        
                </div>
            );
        }
    }

    renderMudaProduto = () => {
        if ( (global.ProductObj.produto.qtdeSabores > 1) && (global.ProductObj.produto.alterandoIndex < 0) ){
            return (
                <div style={{display: 'flex', margin: '6px 0 6px 0'}} onClick={() => {this.alteraSaborProduto()}}>
                    <Cached style={{margin: 'auto 0 auto 0'}}/>
                    <text style={{fontSize:'16px', color: '#777', marginLeft: '4px', margin: 'auto 0 auto 4px'}}>Mudar este produto</text>
                </div>
            );
        }        
    }    

    salvaProduto = () => {

        var tipoOpe = global.ProductObj.produto.alterandoIndex;
        
        global.ProductObj.salvaProdutoCarrinho();

        this.props.closeFunc();
    }

    renderTxtBtn = () => {

        var tipoOpe = global.ProductObj.produto.alterandoIndex;
        var ultimoSabor = (global.ProductObj.produto.saborAtual == (global.ProductObj.produto.qtdeSabores - 1));

        if (global.ProductObj.produto?.sabores?.length > 1){
            if (global.ProductObj.produto.sabores[1].produto){
                ultimoSabor = true;
            }
        }

        if (tipoOpe >= 0) {
            return 'Alterar produto'
        }else{
            return (ultimoSabor) ? 'Adicionar ao carrinho' : 'Escolher segundo sabor'
        }
    }

    renderPrecoSabor = (preco) => {
        
        if (global.ProductObj.produto.qtdeSabores <= 1){
            return (
                <text style={{fontSize:'16px', fontWeight:'bold', color: '#777'}}>R$ {preco.replace('.', ',')}</text>
            );
        }       
        
    }

    renderFidelidadeLinha = (pontosGerados) => {

        if ((global.programaFidelidade == 1) && (pontosGerados > 0)){
            return (
                <div ref={(ref) => this.divTotalProd = ref} style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div width='50%' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        <text style={{fontSize:'15px', fontWeight:'500'}}>Ponto(s) Gerado(s)</text>
                    </div>
                    <div width='50%' style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: '18px'}}>
                        <text style={{fontSize:'18px', fontWeight:'bold', color: '#777'}}>{(pontosGerados)} Ponto(s)</text>
                    </div>
                </div> 
            );
        }
    }

    renderSelecionaAdicionais = () => {

        const { classes } = this.props;
        var prod = global.ProductObj.getSaborAtual().produto;

        var precoAtual = global.ProductObj.produto.valorUnit; 

        var ultimoSabor = global.ProductObj.produto.sabores[global.ProductObj.produto.qtdeSabores - 1].produto;

        var strValorTotal = (precoAtual * this.state.tmpQtdProd).toFixed(2);

        var tipoOpe = global.ProductObj.produto.alterandoIndex;

        var qtdeAtual = this.state.tmpQtdProd;
        qtdeAtual     = (typeof qtdeAtual == "number") ? qtdeAtual : parseFloat(qtdeAtual);
        qtdeAtual     = qtdeAtual.toFixed( (prod.unidade == "KG") ? 3 : 0 ).replace('.' , ',');

        var qtdeMult  = prod.unidadeMul;
        qtdeMult      = (typeof qtdeMult == "number") ? qtdeMult : parseFloat(qtdeMult);
        qtdeMult      = qtdeMult.toFixed( (prod.unidade == "KG") ? 3 : 0 ).replace('.' , ',');
        qtdeMult      = (prod.unidade == "KG") ? qtdeMult : '';

        var pontosBonus = 0;

        try {
            pontosBonus = parseInt(global.ProductObj.produto.grupoProdsFideGera);
        } catch (error) {
            pontosBonus = 0;
        }

        var arrResult = {
            "pontosAdicBonus" : 0
        }

        var precoSemAdic = global.ProductObj.getPrecoProdSemAdic(arrResult);
        var valorCalcPontos = precoSemAdic;

        var pontosGerados = Math.floor(((valorCalcPontos) / global.programaFidelidadeFatorValMin)) + ((pontosBonus));
        
        pontosGerados = (pontosGerados < 0) ? 0 : pontosGerados;

        pontosGerados = pontosGerados + arrResult["pontosAdicBonus"];

        pontosGerados = (pontosGerados < 0) ? 0 : pontosGerados;

        pontosGerados = (pontosGerados * this.state.tmpQtdProd);

        return (

            <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px', minHeight: '100vh'}}>
                <div>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            {this.renderSelecaoProd()}
                        </div> 
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            <div>
                                <div>
                                    <text style={{color: '#ba2d20', fontSize:'18px', fontWeight:'bold'}}>{prod.nomeProduto + ( (global.mostraUnidade) ? ' (' + prod.unidade + ')' : '' )}</text>
                                </div>
                                <div>
                                    <text style={{display: 'inline-block', fontSize:'13px', lineHeight: '18px'}}>{prod.descricao}</text>
                                </div>  
                                <div >
                                    {this.renderPrecoSabor(prod.preco)}
                                    {this.renderMudaProduto()}
                                </div> 
                            </div>
                            {this.renderImageProd(global.cod_restaurante, prod.imagem)}
                        </div>    
                        <div style={{marginTop: '10px'}} className={classes.separator}></div>     
                        <div ref={(ref) => this.divTotalProd = ref} style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            <div width='50%' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <text style={{fontSize:'15px', fontWeight:'500'}}>Valor unitário do produto</text>
                            </div>
                            <div width='50%' style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: '18px'}}>
                                <text style={{fontSize:'15px', fontWeight:'500',}}>R$ {(precoAtual).replace('.', ',')}</text>
                            </div>
                        </div>    
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            <div width='40%' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <text style={{fontSize:'15px', fontWeight:'500'}}>Quantidade deste produto</text>
                            </div>
                            <div width='60%' style={{marginLeft: 'auto', marginRight: '0px', display: 'flex', flexDirection: 'row'}}>
                                <IconButton
                                    style={{color: '#ba2d20', display: 'flex', flexDirection: 'column'}}
                                    aria-label="open drawer"
                                    onClick={() => {
                                        this.clickChangeQtdeProd(false)
                                    }}
                                >
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <text style={{fontSize: '12px'}}>{qtdeMult}</text>
                                        <Remove />
                                    </div>
                                </IconButton>
                                
                                <text style={{fontSize:'15px', fontWeight:'bold', marginBottom: 'auto', marginTop: 'auto'}}>{qtdeAtual}</text>

                                <IconButton
                                    style={{color: '#339119', display: 'flex', flexDirection: 'column'}}
                                    aria-label="open drawer"
                                    onClick={() => {
                                        this.clickChangeQtdeProd(true)
                                    }}
                                >
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <text style={{fontSize: '12px'}}>{qtdeMult}</text>
                                        <Add />
                                    </div>
                                </IconButton>
                            </div>
                        </div> 
                        <div ref={(ref) => this.divTotalProd = ref} style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            <div width='50%' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <text style={{fontSize:'15px', fontWeight:'500'}}>Total do produto</text>
                            </div>
                            <div width='50%' style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: '18px'}}>
                                <text style={{fontSize:'18px', fontWeight:'bold', color: '#777'}}>R$ {(strValorTotal).replace('.', ',')}</text>
                            </div>
                        </div> 
                        {this.renderFidelidadeLinha(pontosGerados)}
                        <div style={{marginTop: '10px'}} className={classes.separator}></div>        
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            {this.renderRemocoes(prod)}
                        </div>   
                        {this.renderAdicoes(prod)}
                        {
                            ((global.ProductObj.produto.saborAtual) == (global.ProductObj.produto.qtdeSabores - 1)) ? this.renderEmbutidos(prod) : null
                        }
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            {this.renderObs(prod)}
                        </div> 
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                            <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => {
                                        if (global.ProductObj.verificaAdicionais()){
                                            if (ultimoSabor) {
                                                this.salvaProduto();
                                            }else{
                                                this.changeSaborAtual(1);
                                            }
                                                                                        
                                        }else{
                                            BS_ALE(this, 'Atenção', 'Verifique os adicionais obrigatórios');
                                        }     
                                    }}
                                    style={{backgroundColor: '#3496eb'}}
                                >
                                {this.renderTxtBtn()}
                            </Button>
                        </div> 
                    </div> 
                </div>
            </div>

                       

        );
    }

    resolveRender = () => {
        if (global.ProductObj.getSaborAtual()){
            if (global.ProductObj.produto.qtdeSabores == 0){
                return this.renderQtdeSabores(this.state.grupos_prods);
            }else if (global.ProductObj.getSaborAtual().mudando){
                return this.renderSelecionaSabor();
            }else if (global.ProductObj.getSaborAtual().produto){
                return this.renderSelecionaAdicionais();
            }else{
                return this.renderSelecionaSabor();
            }
        }else{
            if (global.ProductObj.produto.qtdeSabores == 0){
                return this.renderQtdeSabores(this.state.grupos_prods);
            }else {
                this.props.navigate("/");
            }            
        }
    }

    resolveBackButton = () => {

        if (global.ProductObj.getSaborAtual()){
            if (global.ProductObj.produto.alterandoIndex >= 0) {
                if (this.props.closeFunc){
                    return this.props.closeFunc();
                }

                this.props.navigate("/carrinho-compra");
            }else if (global.ProductObj.getSaborAtual().mudando){
                if (global.ProductObj.produto.saborAtual <= 0) {

                    if (this.props.closeFunc){
                        return this.props.closeFunc();
                    }

                    this.props.navigate("/");
                }else{
                    global.ProductObj.getSaborAtual().mudando = false;
                    global.ProductObj.setSaborAtual(global.ProductObj.produto.saborAtual - 1);
                }
            }else if (global.ProductObj.getSaborAtual().produto){    
                if (global.ProductObj.produto.grupoProdsMisto > 1){
                    this.alteraSaborProduto();
                }else{

                    if (this.props.closeFunc){
                        return this.props.closeFunc();
                    }

                    this.props.navigate("/");
                }                
            }else{
                if (global.ProductObj.produto.saborAtual <= 0) {

                    if (this.props.closeFunc){
                        return this.props.closeFunc();
                    }

                    this.props.navigate("/");
                }else{
                    global.ProductObj.setSaborAtual(global.ProductObj.produto.saborAtual - 1);
                }
                
            }
        }else{

            if (this.props.closeFunc){
                return this.props.closeFunc();
            }

            this.props.navigate("/");
        }

        this.setState({
            refresh : true,
        });
    }

    resolveAlerta = () => {
        this.alteraSaborProdutoConfirmado();
        this.setState({
            alertaNewSabor : false,
        });
    }
	
	mudaProduto = () => {
        global.ProductObj.setSabor(this.state.tmpNewSabor);
        this.fecharPesquisa += 1;
        this.setState({
            qtde : 1,
            tmpNewSabor : [],
            alertaNewSabor : false,
        });
    }    

    renderDialogImage = () => {

        const { classes } = this.props;

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes.color]: '#000',
            [classes.absolute]: 'absolute',
            [classes.fixed]: 'fixed'
          });

        var versaoTermoAtual = this.state.versaoTermoAtual

        return(
          <Dialog
              open={ (this.state.imgDetalhes != null) }
              onClose={() => {
                this.setState({
                    infoObrigOpen: false
                });
              }}
              disableBackdropClick 
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ container: classes.root, paper: classes.paperImg }}
              style={{maxWidth: '720px', margin: '0 auto 0 auto',}}
          >
              <div className={appBarClasses} style={{display: 'flex', flexDirection: 'column', backgroundColor: "#000", marginBottom: '0px'}}>
                <div style={{height: '50px', width: '100%', display: 'flex', position: 'relative'}}>
                    <text style={{fontSize: '18px', fontWeight: 'bold', color: '#fff', margin: 'auto auto auto auto'}}></text>
                    <div onClick={() => { this.setState({ imgDetalhes : null }) }} style={{fontSize: '24px', fontWeight: '400', color: '#fff', position: 'absolute', top: '0%', height: '100%', width: '50px', left: '0px'}}>
                        <div style={{display: 'flex', width: '100%', height: '100%'}}>
                            <ArrowBack style={{ margin: 'auto', }}/>
                        </div>
                    </div>
                </div>                    
              </div>
              <DialogContent style={{padding: '5px'}}>
                  <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                      <div>
                        <img src={this.state.imgDetalhes} style={{margin: '1%', width: '98%'}}></img>
                      </div>
                  </div>
              </DialogContent>
          </Dialog>
        );
    }

    render() {
        const { classes } = this.props;

        var mostraSearch = true;

        if (global.ProductObj.getSaborAtual()){
            if (global.ProductObj.getSaborAtual().produto){
                if (!global.ProductObj.getSaborAtual().mudando){
                    mostraSearch = false;
                }
            }
        }     
        
        var showTituloAlt = this.state.mostraTituloAlt;

        return (
            <div className={classes.myBody} style={{marginTop: '0px',}}>
                <div className={classes.container}>
                    <Card>
                        <div className={classes.form}>
                            <Header context={this} defaultIcon={mostraSearch} searchValue={this.state.pesquisaTxt} fecharPesquisa={this.fecharPesquisa} searchClick={(e) => this.abrePesquisa(e)} searchFunc={(e) => this.pesquisaLista(e)} myBackgroundColor={ (this.state.mostraTituloAlt) ? "#000" : "#fff"} leftLinks={
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    <div style={{whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '0px', marginBottom: '0px', width: (showTituloAlt) ? '100%' : 'max-content' }} className={""}>
                                        {this.renderMenu(this.state.grupos_prods)}
                                    </div>                                    
                                </div>
                            } />
                            <CardBody>
                                {this.resolveRender()}
                            </CardBody>
                        </div>                
                    </Card>
                </div>
                <Confirmacao 
                    showAlert={this.state.alertaNewSabor}
                    onClose={this.handleCloseAlerta}
                    callbackYes={() => {this.resolveAlerta()}} 
                    title={'Atenção'}
                    msg={'Mudando o produto você precisará definir novamente os adicionais, remoções e observações deste item.\n\nDeseja mesmo mudar o produto?'}
                    callbackNo={() => {
                        this.handleCloseAlerta();
                        global.ProductObj.getSaborAtual().mudando = false;
                        
                        this.setState({
                            refresh : true,
                        });
                    }}
                    />
                <Dialog
                    open={this.state.alertaNewSaboor}
                    onClose={this.handleCloseAlerta}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Mudando o produto você precisará definir novamente os adicionais, remoções e observações deste item.<br /><br />Deseja mesmo mudar o produto?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => this.resolveAlerta()} color="primary" style={{backgroundColor: '#03a1fc'}}>
                        Sim
                    </Button>
                    <Button onClick={() => {
                        this.handleCloseAlerta();
                        this.resolveBackButton();
                    }} color="primary" style={{backgroundColor: '#03a1fc'}} >
                        Não
                    </Button>
                    </DialogActions>
                </Dialog>
                {this.renderDialogImage()}
            </div>
        );
    }
}

export default withStyles(SelecionaProd_selecionaSabor, styles);
